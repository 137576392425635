// import logo from "./logo.svg";
import "./App.css";
import React from "react";
import Routes from "./Routes/Routing";
function App() {
  return (
    <>
      <Routes></Routes>;
    </>
  );
}

export default App;
