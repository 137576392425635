import React, { useEffect, useState, useRef } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getSingleAPI, postAPI, putAPI } from "./Action";
import RenderLoader from "../../PanelComponent/RenderLoader";
import { getCookies } from "../../Utils/cookies";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}
const CreatePlan = () => {
  const { id } = useParams();
  const history = useNavigate();
  const [editCase, setEditCase] = useState(false);
  const [feature, setFeature] = useState([]);
  const [values, setValues] = useState({});
  const [loader, setLoader] = useState(false);

  const handleChange = (e) => {
    e.preventDefault();
    let name = e.target.name;
    let val = e.target.value;
    setValues({ ...values, [name]: val })
  }

  const handleAddMoreFeatures = (index, event) => {
    const newValues = [...feature];
    newValues[index].value = event.target.value;
    setFeature(newValues);
    
    if (newValues && newValues.length > 0) {
      let arr = [];
      newValues.map((item) => {
        item.value != '' && arr.push(item);
      });
      setValues({ ...values, ['features']: arr });
    }
  };

  const handleRemoveAddMore = (index) => {
    let newArray = feature;
    newArray.splice(index, 1);
    setFeature(newArray);
    setValues({ ...values, ['features']: newArray });
  }

  const handleAddMore = () => {
    setFeature([...feature, { value: '' }]);
  }

  const handleCreate = async (e) => {
    e.preventDefault();
    setLoader(true);
    if (
      values.name != "" &&
      values.price != "" &&
      values.features != "" &&
      values.duration != ""
    ) {
      if (editCase) {
        putAPI(id, values).then((response) => {
          if (response.status) {
            toast.success(response.message);
            setEditCase(false);
            setLoader(false);
            history("/admin/plan");
          } else {
            toast.error(response.message);
            setLoader(false);
          }
        });
      } else {
        postAPI(values).then((response) => {
          if (response.status) {
            toast.success(response.message);
            setEditCase(false);
            setLoader(false);
            history("/admin/plan");
          } else {
            toast.error(response.message);
            setLoader(false);
          }
        });
      }
    } else {
      toast.error("Please fill all required field!");
      setLoader(false);
    }
  };

  const fetchData = async () => {
    if (getCookies('_isJwt') === 'true' ? true : false) {
      if (window.location.pathname === '/admin/plan-edit/' + id && id != '') {
        setLoader(true)
        await getSingleAPI(id).then(response => {
          if (response.status) {
            const data = response?.data;
            if (data) {
              setFeature(data.features);
              setEditCase(true);
              setValues(data);
              setLoader(false);
            } else {
              toast.error("some error occured", response.message);
              // history('/404');
            }
          } else {
            toast.error("some error occured", response.message);
            // history('/404');
          }
        });
      }
    }
  }

  useEffect(() => {
    window.scroll(0, 0);
    fetchData();
  }, []);
  return (
    <>
      <div className="nk-content">
        <div className="container-fluid">
          <div className="nk-content-inner">
            <div className="nk-content-body">
              <div className="nk-block">
                <div className="card card-bordered">
                  <div className="card-aside-wrap">
                    <div className="card-inner card-inner-lg">
                      <div className="nk-block-head nk-block-head-lg">
                        <div className="nk-block-head-content">
                          <h4 className="nk-block-title">
                            {editCase ? "Edit Subscription Plan" : "Create Subscription Plan"}
                          </h4>
                        </div>
                      </div>
                      {loader ? <RenderLoader trans={true} /> : null}
                      <div className="nk-block">
                        <div className="nk-data data-list">
                          <div className="data-head">
                            <h6 className="overline-title">Normal Details</h6>
                          </div>

                          <div className="data-item">
                            <div className="data-col">
                              <span className="data-label">Plan Name*</span>
                              <div
                                className="form-control-wrap"
                                style={{ width: "100%" }}
                              >
                                <input
                                  type="text"
                                  value={values.name ? values.name : ''}
                                  onChange={handleChange}
                                  name="name"
                                  placeholder="Enter plan name"
                                  required
                                  className="form-control form-control-lg"
                                  style={{ width: "100%" }}
                                  maxLength={50}

                                />
                              </div>
                            </div>
                          </div>

                          <div className="data-item">
                            <div className="data-col">
                              <span className="data-label">Plan Price*</span>
                              <div
                                className="form-control-wrap"
                                style={{ width: "100%" }}
                              >
                                <input
                                  type="number"
                                  value={values.price ? values.price : ''}
                                  onChange={handleChange}
                                  name="price"
                                  placeholder="Enter plan price"
                                  required
                                  className="form-control form-control-lg"
                                  style={{ width: "100%" }}

                                />
                              </div>
                            </div>
                          </div>  

                          <div className="data-item">
                            <div className="data-col">
                              <span className="data-label">Plan Duration Type</span>
                              <div
                                className="form-control-wrap"
                                style={{ width: "100%" }}
                              >
                                <select
                                  type="text"
                                  value={values.duration_type}
                                  onChange={handleChange}
                                  name="duration_type"
                                  required
                                  className="form-control form-control-lg"
                                  style={{ width: "100%" }}
                                >
                                  <option value="Days">Days</option>
                                  <option value="Week">Week</option>
                                  <option value="Month">Month</option>
                                </select>
                              </div>
                            </div>
                          </div>

                          <div className="data-item">
                            <div className="data-col">
                              <span className="data-label">Plan Duration*</span>
                              <div
                                className="form-control-wrap"
                                style={{ width: "100%" }}
                              >
                                <input
                                  type="number"
                                  value={values.duration ? values.duration : ''}
                                  onChange={handleChange}
                                  name="duration"
                                  placeholder="Enter plan duration in days"
                                  required
                                  className="form-control form-control-lg"
                                  style={{ width: "100%" }}

                                />
                              </div>
                            </div>
                          </div>

                          <div className="data-item">
                            <div className="data-col">
                              <span className="data-label">Features*</span>
                              <div
                                className="form-control-wrap"
                                style={{ width: "100%" }}
                              >

                                {feature.map((item, index) => (
                                  <div key={index}>
                                    <span onClick={(e) => handleRemoveAddMore(index)} className="btn-warning float-right">X</span>
                                    <input
                                      type="text"
                                      value={item.value}
                                      onChange={(event) => handleAddMoreFeatures(index, event)}
                                      name="duration"
                                      placeholder="Enter plan features"
                                      className="form-control form-control-lg"
                                      style={{ width: "100%" }}
                                      autoComplete="off"
                                      maxLength={300}
                                    />

                                  </div>
                                ))}
                                <button type="button" className="btn-primary" onClick={handleAddMore}>Add More</button>

                              </div>
                            </div>
                          </div>


                          <div className="data-item">
                            <div className="data-col">
                              <span className="data-label">Consultant</span>
                              <div
                                className="form-control-wrap"
                                style={{ width: "100%" }}
                              >
                                <select
                                  type="text"
                                  value={values.consultant}
                                  onChange={handleChange}
                                  name="consultant"
                                  required
                                  className="form-control form-control-lg"
                                  style={{ width: "100%" }}
                                >
                                  <option value={false} key={false}>NO</option>
                                  <option value={true} key={true}>YES </option>
                                </select>
                              </div>
                            </div>
                          </div>

                        </div>

                        <div className="nk-data data-list">
                          <div className="data-item">
                            <div className="data-col">
                              <div
                                className="form-control-wrap"
                                style={{
                                  width: "100%",
                                  paddingLeft: "80%"
                                }}
                              >
                                <button
                                  className="btn btn-lg btn-primary btn-block"
                                  onClick={(e) => handleCreate(e)}
                                >
                                  {editCase ? "Save" : "Create"}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Toaster position="top-center" reverseOrder={false} duration={3000} />
    </>
  );
};
export default CreatePlan;
