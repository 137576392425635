import React, { useEffect, useState, useRef } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useLocation, useNavigate, useParams, Link } from "react-router-dom";
import {
  getSingleAPI,
  postAPI,
  putAPI,
  getGradeListAPI
} from "./Action";
import { Editor } from "@tinymce/tinymce-react";
import RenderLoader from "../../PanelComponent/RenderLoader";
import { getCookies } from "../../Utils/cookies";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { postMethod, getMethod } from "../../Utils/Service";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}
const CreateStudent = () => {
  const { id } = useParams();
  const query = useQuery();
  const editorRef = useRef(null);
  const history = useNavigate();
  const [editCase, setEditCase] = useState(false);
  const imageRef1 = useRef();
  const [gradeList, setGradeList] = useState([]);
  const [referenceList, setReferenceList] = useState([]);

  const [values, setValues] = useState({ role: 2, active: true });
  const [loader, setLoader] = useState(false);

  const handleChange = (e) => {
    e.preventDefault();
    let name = e.target.name;
    let val = e.target.value;
    setValues({ ...values, [name]: val });
  };

  const handleCreate = async (e) => {
    e.preventDefault();
    setLoader(true);
    if (
      values.name != "" &&
      values.email != "" &&
      values.mobile != ""
    ) {
      if (editCase) {
        putAPI(id, values).then((response) => {
          if (response.status) {
            toast.success(response.message);
            setEditCase(false);
            setLoader(false);
            history("/admin/student");
          } else {
            toast.error(response.message);
            setLoader(false);
          }
        });
      } else {
        postAPI(values).then((response) => {
          if (response.status) {
            toast.success(response.message);
            setEditCase(false);
            setLoader(false);
            history("/admin/student");
          } else {
            toast.error(response.message);
            setLoader(false);
          }
        });
      }
    } else {
      toast.error("Please fill all required field!");
      setLoader(false);
    }
  };


  const fetchData = async () => {
    if (getCookies("_isJwt") === "true" ? true : false) {
      if (
        window.location.pathname === "/admin/student-edit/" + id &&
        id != ""
      ) {
        setLoader(true);
        await getSingleAPI(id).then(async (response) => {
          if (response.status) {
            const data = response?.data;
            if (data) {
              setEditCase(true);
              setValues(data);
              setLoader(false);
            } else {
              toast.error("some error occured", response.message);
              // history('/404');
            }
          } else {
            toast.error("some error occured", response.message);
            // history('/404');
          }
        });
      }




      await getGradeListAPI().then(async (response) => {
        if (response?.status) {
          setGradeList(response.data);
        }
      });


    }
  };

  useEffect(() => {
    window.scroll(0, 0);
    fetchData();
  }, []);
  return (
    <>
      <div className="nk-content">
        <div className="container-fluid">
          <div className="nk-content-inner">
            <div className="nk-content-body">
              <div className="nk-block">
                <div className="card card-bordered">
                  <div className="card-aside-wrap">
                    <div className="card-inner card-inner-lg">
                      <div className="nk-block-head nk-block-head-lg">
                        <div className="nk-block-head-content">
                          <h4 className="nk-block-title">
                            {editCase ? "Edit Students" : "Add Students"}
                          </h4>
                        </div>
                      </div>
                      {loader ? <RenderLoader trans={true} /> : null}
                      <div className="nk-block">
                        <div className="nk-data data-list">
                          <div className="data-head">
                            <h6 className="overline-title">Normal Details</h6>
                          </div>


                          <div className="data-item">
                            <div className="data-col">
                              <span className="data-label">Profile Image*</span>
                              <div
                                className="form-control-wrap"
                                style={{ width: "100%" }}
                              >
                                {(editCase && values.image) ||
                                  values.image ? (
                                  <Link
                                    to={
                                      process.env.REACT_APP_URL +
                                      "/" +
                                      values?.image
                                    }
                                    download
                                    target="_blank"
                                  >
                                    <img
                                      style={{
                                        maxWidth: "200px",
                                        maxHeight: "150px",
                                      }}
                                      src={
                                        process.env.REACT_APP_IMG_URL +
                                        "/" +
                                        values.image
                                      }
                                      className="img-fluid"
                                    />
                                  </Link>
                                ) : null}

                                <input
                                  type="file"
                                  name="image"
                                  ref={imageRef1}
                                  className="form-control-lg"
                                  style={{ width: "50%" }}
                                  accept="image/png, image/gif, image/jpeg"
                                  onChange={async (event) => {
                                    var file = event.target.files[0];
                                    var formData = new FormData();
                                    formData.append("image", file, file.name);
                                    setLoader(true);

                                    await postMethod(
                                      "uploadImage",
                                      formData
                                    ).then((response) => {
                                      if (response?.status) {
                                        setValues({
                                          ...values,
                                          ["image"]: response?.file,
                                        });
                                        setLoader(false);
                                      } else {
                                        toast.error(response.message);
                                        setLoader(false);
                                      }
                                    });
                                  }}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="data-item">
                            <div className="data-col">
                              <span className="data-label">User name*</span>
                              <div
                                className="form-control-wrap"
                                style={{ width: "100%" }}
                              >
                                <input
                                  type="text"
                                  value={
                                    values.name ? values.name : ""
                                  }
                                  onChange={handleChange}
                                  name="name"
                                  placeholder="Enter name"
                                  required
                                  className="form-control form-control-lg"
                                  style={{ width: "100%" }}
                                  maxLength={50}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="data-item">
                            <div className="data-col">
                              <span className="data-label">Mobile*</span>
                              <div
                                className="form-control-wrap"
                                style={{ width: "100%" }}
                              >
                                <div
                                  className="form-control-wrap"
                                  style={{ width: "100%" }}
                                >
                                  <PhoneInput
                                    inputProps={{
                                      name: "mobile",
                                      required: true,
                                      type: "tel",
                                    }}
                                    onChange={(e, data) => {
                                      if (e != "") {
                                        data?.format
                                          ? data?.format[0]
                                            ? setValues({
                                              ...values,
                                              ["mobile"]: data?.format[0] + e,
                                            })
                                            : ""
                                          : "";
                                      } else {
                                        setValues({
                                          ...values,
                                          ["mobile"]: "",
                                        });
                                      }
                                    }}
                                    className="inputFields"
                                    autocomplete="off"
                                    country={"hk"}
                                    value={values.mobile ? values.mobile : ""}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>



                          <div className="data-item">
                            <div className="data-col">
                              <span className="data-label">Email*</span>
                              <div
                                className="form-control-wrap"
                                style={{ width: "100%" }}
                              >
                                <input
                                  type="email"
                                  value={values.email ? values.email : ""}
                                  onChange={handleChange}
                                  name="email"
                                  placeholder="Enter email address"
                                  required
                                  className="form-control form-control-lg"
                                  style={{ width: "100%" }}
                                  maxLength={50}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="data-item">
                            <div className="data-col">
                              <span className="data-label">Password*</span>
                              <div
                                className="form-control-wrap"
                                style={{ width: "100%" }}
                              >
                                <input
                                  type="text"
                                  value={values.password ? values.password : ""}
                                  onChange={handleChange}
                                  name="password"
                                  placeholder="Enter password"
                                  required
                                  className="form-control form-control-lg"
                                  style={{ width: "100%" }}
                                  maxLength={20}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="data-item">
                            <div className="data-col">
                              <span className="data-label">
                                Age
                              </span>
                              <div
                                className="form-control-wrap"
                                style={{ width: "100%" }}
                              >
                                <input
                                  type="text"
                                  value={
                                    values.age
                                      ? values.age
                                      : ""
                                  }
                                  onChange={handleChange}
                                  name="age"
                                  placeholder="Enter user age"
                                  required
                                  className="form-control form-control-lg"
                                  style={{ width: "100%" }}
                                  maxLength={30}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="data-item">
                            <div className="data-col">
                              <span className="data-label">
                                Grade
                              </span>
                              <div
                                className="form-control-wrap"
                                style={{ width: "100%" }}
                              >
                                <select
                                  value={
                                    values.grade
                                      ? values.grade
                                      : ""
                                  }
                                  onChange={handleChange}
                                  name="grade"
                                  className="form-control form-control-lg"
                                  style={{ width: "100%" }}
                                >
                                  <option value="">Select an grade</option>
                                  {gradeList?.length
                                    ? gradeList?.map((n, index) => (
                                      <option value={n?._id} key={index}>
                                        {n?.name ? n?.name : ""}
                                      </option>
                                    ))
                                    : null}
                                </select>
                              </div>
                            </div>
                          </div>

                          <div className="data-item">
                            <div className="data-col">
                              <div
                                className="form-control-wrap"
                                style={{ width: "100%" }}
                              >
                                <div className="data-item">
                                  <div className="data-col">
                                    <span className="data-label">
                                      Introduction
                                    </span>
                                    <div
                                      className="form-control-wrap"
                                      style={{ width: "100%" }}
                                    >
                                      <Editor
                                        max={30}
                                        className="editorStyle"
                                        outputFormat="text"
                                        onInit={(evt, editor) =>
                                          (editorRef.current = editor)
                                        }
                                        onEditorChange={(evt, editor) => {
                                          setValues({
                                            ...values,
                                            ["intro"]:
                                              editorRef.current.getContent(),
                                          });
                                        }}
                                        value={
                                          values ? values.intro : ""
                                        }
                                        apiKey={
                                          process.env.REACT_APP_TINMCY_KEY
                                        }
                                        init={{
                                          width: "100%",
                                          height: 410,
                                          skin: "naked",
                                          menubar: false,
                                          selector: "textarea",
                                          resize: "both",
                                          branding: false,
                                          draggable_modal: false,
                                          statusbar: false,
                                          theme: "silver",
                                          draggable_modal: false,
                                          toolbar_mode: "floating",
                                          toolbar_location: "bottom",
                                          placeholder:
                                            "Start typing discription here",
                                          plugins: [],
                                          toolbar:
                                            "fontsizeselect fontDecoration alignment forecolor emoticons",
                                          toolbar_groups: {
                                            fontDecoration: {
                                              icon: "bold",
                                              tooltip: "Font Decoration",
                                              items: "bold italic underline",
                                            },
                                            alignment: {
                                              icon: "align-left",
                                              tooltip:
                                                "Alignment | Line Height",
                                              items:
                                                "alignleft aligncenter alignright alignjustify lineheight",
                                            },
                                          },
                                          textcolor_map: [
                                            "#000000",
                                            "Black",
                                            "#FF0000",
                                            "red",
                                            "#008000",
                                            "green",
                                            "#0000FF",
                                            "blue",
                                            "#FC0FC0",
                                            "pink",
                                            "#B200ED",
                                            "violet",
                                            "#FF0090",
                                            "magenta",
                                            "#2E8B57",
                                            "sea",
                                            "#FCA3B7",
                                            "flamingo",
                                            "#2B1700",
                                            "chocolate",
                                            "#A020F0",
                                            "purple",
                                            "#FC6600",
                                            "orange",
                                            "#FF6347",
                                            "tomato",
                                          ],
                                          color_map: [
                                            "#000000",
                                            "Black",
                                            "#FF0000",
                                            "red",
                                            "#008000",
                                            "green",
                                            "#0000FF",
                                            "blue",
                                            "#FC0FC0",
                                            "pink",
                                            "#B200ED",
                                            "violet",
                                            "#FF0090",
                                            "magenta",
                                            "#2E8B57",
                                            "sea",
                                            "#FCA3B7",
                                            "flamingo",
                                            "#2B1700",
                                            "chocolate",
                                            "#A020F0",
                                            "purple",
                                            "#FC6600",
                                            "orange",
                                            "#FF6347",
                                            "tomato",
                                          ],
                                          custom_colors: true,
                                          fontsize_formats:
                                            "8pt 10pt 12pt 14pt 16pt 18pt 24pt 36pt 48pt",
                                          lineheight_formats:
                                            "1.1 1.2 1.3 1.4 1.5 2",
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="data-item">
                            <div className="data-col">
                              <span className="data-label">Gender</span>
                              <div
                                className="form-control-wrap"
                                style={{ width: "100%" }}
                              >

                                <select
                                  value={
                                    values.gender ? values.gender : ""
                                  }
                                  onChange={handleChange}
                                  name="gender"
                                  className="form-control form-control-lg"
                                  style={{ width: "100%" }}
                                >
                                  <option value=""> Select an option</option>
                                  <option value='male'>
                                    Male
                                  </option>
                                  <option value='female'>
                                    Female
                                  </option>
                                </select>
                              </div>
                            </div>
                          </div>

                          <div className="data-item">
                            <div className="data-col">
                              <span className="data-label">Is Active</span>
                              <div
                                className="form-control-wrap"
                                style={{ width: "100%" }}
                              >
                                <select
                                  type="text"
                                  value={values.active}
                                  onChange={handleChange}
                                  name="active"
                                  required
                                  className="form-control form-control-lg"
                                  style={{ width: "100%" }}
                                >
                                  <option value={true} key={true}>
                                    Active{" "}
                                  </option>
                                  <option value={false} key={false}>
                                    In Active
                                  </option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="nk-data data-list">
                          <div className="data-item">
                            <div className="data-col">
                              <div
                                className="form-control-wrap"
                                style={{
                                  width: "100%",
                                  paddingLeft: "80%"
                                }}
                              >
                                <button
                                  className="btn btn-lg btn-primary btn-block"
                                  onClick={(e) => handleCreate(e)}
                                >
                                  {editCase ? "Save" : "Create"}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Toaster position="top-center" reverseOrder={false} duration={3000} />
    </>
  );
};
export default CreateStudent;
