import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { useNavigate, Link } from 'react-router-dom';
import { dateFormatConverter } from '../../Utils/Logic';
import { getAllAPI, deleteAPI, getGradeListAPI, getAllExcelExportAPI } from './Action';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import toast, { Toaster } from 'react-hot-toast';
import RenderLoader from '../../PanelComponent/RenderLoader';
import { getCookies } from '../../Utils/cookies';
import Modal from "react-modal";
import { ExportToExcel } from '../ExportToExcel';
import user_ic from "../../assets/img/user_ic.svg";

const Student = () => {
    const history = useNavigate();
    const [loader, setLoader] = useState(true);
    const [totalProduct, setTotalProduct] = useState(0);
    const [dataList, setDataList] = useState([]);
    const [gradeList, setGradeList] = useState([]);
    const [grade, setGrade] = useState([]);
    const [paginationIndex, setPaginationIndex] = useState(1);
    const [search, setSearch] = useState("");
    const [activeStatus, setActiveStatus] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [hideClass, setHideClass] = useState({});
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [excelData, setExcelData] = useState([]);

    const customStyles = {
        content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            maxWidth: "500px",
            wordWrap: "break-word"
        },
    };

    const getListAPI = async (limit, page, search, grade, activeStatus) => {
        let postBodyObj = {
            role: 2,
            limit: limit,
            page: page,
            search: search,
            grade: grade,
            status: activeStatus
        };
        await getAllAPI(postBodyObj).then(response => {
            if (response.status) {
                setDataList(response.data);
                setTotalProduct(response.total)
                setLoader(false)
            } else {
                setLoader(false)
            }
        });
        await getAllExcelExportAPI(postBodyObj).then(response => {
            if (response.status) {

                // reshaping the array for excel import
                const customHeadings = response.data.map(item => ({
                    "Name": item?.name,
                    "Email": item?.email,
                    "Mobile": item?.mobile,
                    "Gender": item?.gender,
                    "Age": item?.age,
                    "Grade": item?.grade?.name,
                    "Introduction": item?.intro,
                    "Created On": dateFormatConverter(item?.createdAt),
                    "Status": (item?.active) ? "Active" : "In-active",
                }))
                setExcelData(customHeadings);
                setLoader(false)
            } else {
                setLoader(false)
            }
        });
    };

    const confirmAction = (id, index) => {
        confirmAlert({
            title: 'Confirm Action',
            message: 'Are you sure you want to delete?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {
                        setLoader(true)
                        await deleteAPI(id).then(response => {
                            if (response?.status === true) {
                                getListAPI(50, paginationIndex, search, grade, activeStatus)
                                toast.success(response.message);
                                setLoader(false)
                            } else {
                                toast.error(response.message)
                                setLoader(false)
                            }
                        })
                    }
                },
                {
                    label: 'No',
                    onClick: () => { }
                }
            ]
        });
    }

    const openModal = () => {
        setHideClass({
            position: "relative",
            zIndex: "-1"
        })
        setIsOpen(true);
    };
    const close = () => {
        setIsOpen(false);
        setHideClass({})
    };

    const reset = () => {
        setIsOpen(false);
        setHideClass({})
        setGrade("");
        setActiveStatus("");
        setSearch("");
        getListAPI(50, 1);
    };

    const handleSubmitSearch = async (e) => {
        e.preventDefault();
        const { search } = e.target.elements;
        setSearch(search.value)
        getListAPI(50, 1, search.value, grade, activeStatus);
    }

    const handleSubmitSearchP = async (e) => {
        e.preventDefault();
        const search = e.target.value;
        setSearch(search);
        getListAPI(50, 1, search, grade, activeStatus);
    }
    
    const handleDateRange = async (e) => {
        e.preventDefault();
        getListAPI(50, 1, search, grade, activeStatus);
        close();
    }

    const handleSubmitGrade = async (value) => {
        setGrade(value)
    }

    const handleActiveStatus = async (value) => {
        setActiveStatus(value)
    }

    const getListing = async () => {
        await getGradeListAPI().then(response => {
            if (response.status) {
                setGradeList(response.data);
                setLoader(false)
            } else {
                setLoader(false)
            }
        })
    };


    useEffect(() => {
        if (getCookies('_isJwt') === 'true' ? true : false) {
            getListAPI(50, 1, search, grade, activeStatus);
            getListing();
        }
    }, []);

    var divisor = (totalProduct / 50) >> 0;
    var remainder = totalProduct % 50;
    return (
        <>
            <div className="sideBarUL nk-content" >
                <div className="container-fluid" >
                    <div className="nk-content-inner" >
                        <div className="nk-content-body" >
                            <div className="nk-block" >
                                <div className="card card-bordered" >
                                    <div className="card-aside-wrap" >
                                        <div className="card-inner card-inner-lg">
                                            <div className="nk-block-head nk-block-head-lg" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                <div className="nk-block-head-content">
                                                    <h4 className="nk-block-title">Student</h4>
                                                    <div className="nk-block-des text-soft">
                                                        {
                                                            totalProduct ?
                                                                <p>You have total {totalProduct === 1 ? totalProduct + ' Student' : totalProduct + ' Student'}</p>
                                                                : null
                                                        }
                                                    </div>
                                                </div>


                                                <div className="toggle-wrap nk-block-tools-toggle">
                                                    <a
                                                        href="#"
                                                        className="btn btn-icon btn-trigger toggle-expand mr-n1"
                                                        data-target="pageMenu"
                                                    >
                                                        <em className="icon ni ni-menu-alt-r"></em>
                                                    </a>
                                                    <div
                                                        className="toggle-expand-content"
                                                        data-content="pageMenu"
                                                    >
                                                        <ul className="nk-block-tools g-3">
                                                            <li>
                                                                <ExportToExcel apiData={excelData} fileName="students" />
                                                            </li>
                                                            <li>
                                                                <Link to="/admin/student-create" className="btn btn-info">Add Student</Link>
                                                            </li>
                                                            <li>
                                                                <span onClick={openModal} className="btn btn-success">Search Filter</span>
                                                            </li>
                                                            <li>
                                                                <span onClick={reset} className="btn btn-success">Reset</span>
                                                            </li>
                                                        </ul>
                                                    </div>

                                                </div>


                                            </div>

                                            {
                                                loader ?
                                                    <RenderLoader trans={true} />
                                                    : null
                                            }

                                            <div style={{ width: '100%' }}>
                                                <div className="nk-block">
                                                    <div className="card card-bordered card-stretch">
                                                        <div className="card-inner-group">
                                                            <div className="card-inner position-relative card-tools-toggle" style={hideClass}>
                                                                <div className='card-search search-wrap active' data-search="category">
                                                                    <div className="card-body">
                                                                        <form className="search-content" onSubmit={(e) => { handleSubmitSearch(e) }}>
                                                                            <input type="text" name='search' id='search' autoComplete='off' className="form-control border-transparent form-focus-none" placeholder="Search by Name..." style={{ paddingLeft: '0px', textTransform: 'none' }} value={search} onChange={(e) => handleSubmitSearchP(e)} />
                                                                            <button className="search-submit btn btn-icon"><em className="icon ni ni-search"></em></button>
                                                                        </form>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="card-inner p-0">
                                                                <div className="nk-tb-list nk-tb-ulist">
                                                                    <div className="nk-tb-item nk-tb-head">
                                                                        <div className="nk-tb-col text-center"><span className="sub-text">Image</span></div>
                                                                        <div className="nk-tb-col text-center"><span className="sub-text">UniqueId</span></div>
                                                                        <div className="nk-tb-col text-center"><span className="sub-text">Name</span></div>
                                                                        <div className="nk-tb-col text-center"><span className="sub-text">Grade</span></div>
                                                                        <div className="nk-tb-col text-center"><span className="sub-text">Email</span></div>
                                                                        <div className="nk-tb-col text-center"><span className="sub-text">Mobile</span></div>
                                                                        {/* <div className="nk-tb-col tb-col-lg"><span className="sub-text text-center">Subscription Status</span></div> */}
                                                                        <div className="nk-tb-col tb-col-md"><span className="sub-text text-center">Created At</span></div>
                                                                        <div className="nk-tb-col tb-col-lg"><span className="sub-text text-center">Active</span></div>
                                                                        <div className="nk-tb-col tb-col-lg"><span className="sub-text text-center">Is Deleted</span></div>
                                                                        <div className="nk-tb-col nk-tb-col-tools text-right">
                                                                            <span className="sub-text">More</span>
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        dataList?.length ?
                                                                            dataList?.map((data, index) =>
                                                                                <div className="nk-tb-item" key={index}>


                                                                                    {/* <div className="nk-tb-col tb-col-lg">
                                                                                        <span className="sub-text text-center">{index+1}</span>
                                                                                    </div> */}

                                                                                    <div className="nk-tb-col">
                                                                                        <div className="image-box">
                                                                                            <Link to={process.env.REACT_APP_URL + '/' + data?.image} download target='_blank'>
                                                                                                {data?.image ?
                                                                                                    <img className="images" src={process.env.REACT_APP_URL + '/' + data?.image} style={{ maxWidth: "50px", maxHeight: "50px" }} />
                                                                                                    :
                                                                                                    <img className="images" src={user_ic} style={{ maxWidth: "50px", maxHeight: "50px" }} />
                                                                                                }
                                                                                            </Link>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div className="nk-tb-col tb-col-lg">
                                                                                        <span className="sub-text text-center">{data?.uniqueId}</span>
                                                                                    </div>

                                                                                    <div className="nk-tb-col tb-col-lg">
                                                                                        <span className="sub-text text-center">{data?.name}</span>
                                                                                    </div>
                                                                                    <div className="nk-tb-col tb-col-lg">
                                                                                        <span className="sub-text text-center">{(data?.grade) ? data?.grade?.name : ''}</span>
                                                                                    </div>
                                                                                    <div className="nk-tb-col tb-col-lg">
                                                                                        <span className="sub-text text-center">{data?.email}</span>
                                                                                    </div>
                                                                                    <div className="nk-tb-col tb-col-lg">
                                                                                        <span className="sub-text text-center">{data?.mobile}</span>
                                                                                    </div>

                                                                                    {/* <div className="nk-tb-col tb-col-lg">
                                                                                        <span className="sub-text text-center">
                                                                                            {data?.subscription_status ?
                                                                                                <span className="badge badge-success">YES</span>
                                                                                                :
                                                                                                <span className="badge badge-danger">NO</span>
                                                                                            }
                                                                                        </span>
                                                                                    </div> */}

                                                                                    <div className="nk-tb-col tb-col-lg">
                                                                                        <span className="sub-text text-center">{dateFormatConverter(data?.createdAt)}</span>
                                                                                    </div>

                                                                                    <div className="nk-tb-col tb-col-lg">
                                                                                        <span className="sub-text text-center">{data?.active ? (<span className="badge badge-success">Active</span>) :
                                                                                            <span className="badge badge-danger">In Active</span>
                                                                                        }</span>
                                                                                    </div>

                                                                                    <div className="nk-tb-col tb-col-lg">
                                                                                        <span className="sub-text text-center">{data?.deleted ? (<span className="badge badge-danger">Deleted</span>) :
                                                                                            <span className="badge badge-success">Active</span>
                                                                                        }</span>
                                                                                    </div>

                                                                                    <div className="nk-tb-col nk-tb-col-tools">
                                                                                        <ul className="nk-tb-actions gx-1">
                                                                                            <li>
                                                                                                <div className="drodown">
                                                                                                    <a href="#" className="dropdown-toggle btn btn-icon btn-trigger" data-toggle="dropdown"><em className="icon ni ni-more-h"></em></a>
                                                                                                    <div className="dropdown-menu dropdown-menu-right">
                                                                                                        <ul className="link-list-opt no-bdr">
                                                                                                            <li><a onClick={(e) => { history('/admin/student-edit/' + data?._id) }}><em className="icon ni ni-pen"></em><span>Edit</span></a></li>
                                                                                                            <li><a onClick={(e) => confirmAction(data?._id, index)}><em className="icon ni ni-trash"></em><span>Delete</span></a></li>
                                                                                                        </ul>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </li>
                                                                                        </ul>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                            : null
                                                                    }
                                                                </div>
                                                                {
                                                                    dataList?.length ?
                                                                        null
                                                                        :
                                                                        <div className='row justify-content-center fullDiv' style={{ marginLeft: '0px' }}>
                                                                            <p className='tb-status text-success sub-text text-center' style={{ fontSize: '16px', fontWeight: '600', lineHeight: '36px' }}>
                                                                                Not Found!
                                                                            </p>
                                                                        </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='' style={{ padding: '15px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                {

                                                    totalProduct > 1 ?
                                                        <ReactPaginate
                                                            previousLabel={"Prev"}
                                                            nextLabel={"Next"}
                                                            breakLabel={"..."}
                                                            breakClassName={"break-me"}
                                                            pageCount={remainder >= 1 ? divisor + 1 : divisor}
                                                            marginPagesDisplayed={2}
                                                            pageRangeDisplayed={5}
                                                            onPageChange={async (e) => { setLoader(true); setPaginationIndex(e.selected + 1); getListAPI(50, e.selected + 1, search, grade, activeStatus) }}
                                                            containerClassName={"pagination"}
                                                            subContainerClassName={"pages pagination"}
                                                            activeClassName={"active"} />
                                                        :
                                                        null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >


            <Modal
                ariaHideApp={false}
                isOpen={isOpen}
                style={customStyles}
                contentLabel={"Advance Filter"}
            >
                <div className="card-inner card-inner-lg p-2 zindex-5">
                    <div className="nk-block-head nk-block-head-lg">
                        <div className="nk-block-head-content" style={{ display: "flex" }}>
                            <h4
                                className="nk-block-title"
                                style={{ fontSize: "25px", fontWeight: "400" }}
                            >Advance Filter</h4>
                            <a onClick={close} style={{ marginInlineStart: "auto" }}><h4>X</h4></a>
                        </div>
                        <div className="nk-block-head-content" style={{ display: "flex" }}>

                            <div className="nk-block">
                                <div className="nk-data data-list">
                                    <div className="data-head">
                                        <h6 className="overline-title">Normal Details</h6>
                                    </div>


                                    <div className="data-item col-lg-12">
                                        <div className="data-col">
                                            <div className="form-control-wrap" style={{ width: "100%" }}>
                                                <span className="data-label">Grade</span>
                                                <select className="form-control form-control-lg" value={grade} onChange={(e) => handleSubmitGrade(e.target.value)} style={{ textTransform: 'uppercase', width: '100%', padding: '5px 5px', }} >
                                                    <option value="">Select grade</option>
                                                    {
                                                        gradeList?.length ?
                                                            gradeList?.map((n, index) => <option value={n?._id} key={index}>{n?.name ? n?.name : ''}</option>)
                                                            : null
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="data-item col-lg-12">
                                        <div className="data-col">
                                            <div className="form-control-wrap" style={{ width: "100%" }}>
                                                <span className="data-label">Status</span>
                                                <select className="form-control form-control-lg" value={activeStatus} onChange={(e) => handleActiveStatus(e.target.value)} style={{ textTransform: 'uppercase', width: '100%', padding: '5px 5px', }} >
                                                    <option value="">Select option</option>
                                                    <option value={false}>In Active</option>
                                                    <option value={true}>Active</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                    <div className='text-center'>
                        <button className="btn btn-lg btn-primary" onClick={reset}>Reset</button>&nbsp;&nbsp;
                        <button onClick={handleDateRange} className="btn btn-lg btn-primary">Search</button>
                    </div>
                </div>
            </Modal>
            <Toaster
                position="top-center"
                reverseOrder={false}
                duration={3000}
            />
        </>
    )
}
export default Student;
