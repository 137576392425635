import { postMethod, putMethod, deleteMethod, getMethod } from '../../Utils/Service';

const getAllAPI = async (data) => {
        const returnData = await postMethod('subject', data)
        return returnData;
}

const postAPI = async (data) => {
        const returnData = await postMethod('subject/add', data)
        return returnData;
}


const getSingleAPI = async (id) => {
        const returnData = await getMethod('subject/edit/' + id);
        return returnData;
}

const putAPI = async (id, data) => {
        const returnData = await putMethod('subject/update/' + id, data);
        return returnData;
}

const deleteAPI = async (id) => {
        // const returnData = await getMethod('subject/status/' + id);
        const returnData = await deleteMethod('subject/remove/' + id);
        return returnData;
}


export { postAPI, putAPI, deleteAPI, getAllAPI, getSingleAPI };