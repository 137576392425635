import React, { useEffect, useState, useRef } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getSingleAPI, postAPI, putAPI } from "./Action";
import { Editor } from "@tinymce/tinymce-react";
import RenderLoader from "../../PanelComponent/RenderLoader";
import { getCookies } from "../../Utils/cookies";
import { postMethod } from '../../Utils/Service';


function useQuery() {
  return new URLSearchParams(useLocation().search);
}
const CreateService = () => {
  const { id } = useParams();
  const query = useQuery();
  const editorRef = useRef(null);
  const history = useNavigate();
  const [editCase, setEditCase] = useState(false);
  const imageRef1 = useRef();

  const [values, setValues] = useState({});
  const [loader, setLoader] = useState(false);


  const handleChange = (e) => {
    e.preventDefault();
    let name = e.target.name;
    let val = e.target.value;
    setValues({ ...values, [name]: val })
  }

  const handleCreate = async (e) => {
    e.preventDefault();
    setLoader(true);

    if (
      values.slug != "" &&
      values.title != "" &&
      values.description != ""
    ) {
      if (editCase) {
        putAPI(id, values).then((response) => {
          if (response.status) {
            toast.success(response.message);
            setEditCase(false);
            setLoader(false);
            history("/admin/service");
          } else {
            toast.error(response.message);
            setLoader(false);
          }
        });
      } else {
        postAPI(values).then((response) => {
          if (response.status) {
            toast.success(response.message);
            setEditCase(false);
            setLoader(false);
            history("/admin/service");
          } else {
            toast.error(response.message);
            setLoader(false);
          }
        });
      }
    } else {
      toast.error("Please fill all required field!");
      setLoader(false);
    }
  };
  const fetchData = async () => {
    if (getCookies('_isJwt') === 'true' ? true : false) {
      if (window.location.pathname === '/admin/service-edit/' + id && id != '') {
        setLoader(true)
        await getSingleAPI(id).then(response => {
          if (response.status) {
            const data = response?.data;
            if (data) {
              setEditCase(true);
              setValues(data);
              setLoader(false);
            } else {
              toast.error("some error occured", response.message);
              // history('/404');
            }
          } else {
            toast.error("some error occured", response.message);
            // history('/404');
          }
        });
      }
    }
  }

  useEffect(() => {
    window.scroll(0, 0);    
    fetchData();
  }, []);
  return (
    <>
      <div className="nk-content">
        <div className="container-fluid">
          <div className="nk-content-inner">
            <div className="nk-content-body">
              <div className="nk-block">
                <div className="card card-bordered">
                  <div className="card-aside-wrap">
                    <div className="card-inner card-inner-lg">
                      <div className="nk-block-head nk-block-head-lg">
                        <div className="nk-block-head-content">
                          <h4 className="nk-block-title">
                            {editCase ? "Edit Service" : "Create Service"}
                          </h4>
                        </div>
                      </div>
                      {loader ? <RenderLoader trans={true} /> : null}
                      <div className="nk-block">
                        <div className="nk-data data-list">
                          <div className="data-head">
                            <h6 className="overline-title">Normal Details</h6>
                          </div>


                          <div className="data-item">
                            <div className="data-col">
                              <span className="data-label">Slug*</span>
                              <div
                                className="form-control-wrap"
                                style={{ width: "100%" }}
                              >
                                <input
                                  type="text"
                                  value={values.slug ? values.slug : ''}
                                  onChange={handleChange}
                                  name="slug"
                                  placeholder="Enter slug here"
                                  required
                                  className="form-control form-control-lg"
                                  style={{ width: "100%" }}
                                  disabled={editCase}
                                  maxLength={50}
                                />
                              </div>
                            </div>
                          </div>
                          
                          <div className="data-item">
                            <div className="data-col">
                              <span className="data-label">Title*</span>
                              <div
                                className="form-control-wrap"
                                style={{ width: "100%" }}
                              >
                                <input
                                  type="text"
                                  value={values.title ? values.title : ''}
                                  onChange={handleChange}
                                  name="title"
                                  placeholder="Enter title here"
                                  required
                                  className="form-control form-control-lg"
                                  style={{ width: "100%" }}
                                  maxLength={200}
                                />
                              </div>
                            </div>
                          </div>


                          <div className="data-item">
                            <div className="data-col">
                              {/* <span className="data-label">Description*</span> */}
                              <div
                                className="form-control-wrap"
                                style={{ width: "100%" }}
                              >
                                <div className="data-item">
                                  <div className="data-col">
                                    <span className="data-label">
                                      Description*
                                    </span>
                                    <div
                                      className="form-control-wrap"
                                      style={{ width: "100%" }}
                                    >
                                      <Editor
                                        className='editorStyle'
                                        outputFormat='text'
                                        onInit={(evt, editor) => editorRef.current = editor}
                                        onEditorChange={(evt, editor) => {
                                          setValues({ ...values, ['description']: editorRef.current.getContent() })
                                        }}
                                        value={(values) ? values.description : ''}
                                        apiKey={process.env.REACT_APP_TINMCY_KEY}
                                        init={
                                          {
                                            width: '100%',
                                            height: 410,
                                            skin: "naked",
                                            menubar: false,
                                            selector: "textarea",
                                            resize: 'both',
                                            branding: false,
                                            draggable_modal: false,
                                            statusbar: false,
                                            theme: 'silver',
                                            draggable_modal: false,
                                            toolbar_mode: 'floating',
                                            toolbar_location: 'bottom',
                                            placeholder: 'Start typing discription here',
                                            plugins: [],
                                            toolbar: 'fontsizeselect fontDecoration alignment forecolor emoticons',
                                            toolbar_groups: {
                                              fontDecoration: {
                                                icon: 'bold',
                                                tooltip: 'Font Decoration',
                                                items: 'bold italic underline'
                                              },
                                              alignment: {
                                                icon: 'align-left',
                                                tooltip: 'Alignment | Line Height',
                                                items: 'alignleft aligncenter alignright alignjustify lineheight'
                                              }
                                            },
                                            textcolor_map: [
                                              '#000000', 'Black',
                                              '#FF0000', 'red',
                                              '#008000', 'green',
                                              '#0000FF', 'blue',
                                              '#FC0FC0', 'pink',
                                              '#B200ED', 'violet',
                                              '#FF0090', 'magenta',
                                              '#2E8B57', 'sea',
                                              '#FCA3B7', 'flamingo',
                                              '#2B1700', 'chocolate',
                                              '#A020F0', 'purple',
                                              '#FC6600', 'orange',
                                              '#FF6347', 'tomato'
                                            ],
                                            color_map: [
                                              '#000000', 'Black',
                                              '#FF0000', 'red',
                                              '#008000', 'green',
                                              '#0000FF', 'blue',
                                              '#FC0FC0', 'pink',
                                              '#B200ED', 'violet',
                                              '#FF0090', 'magenta',
                                              '#2E8B57', 'sea',
                                              '#FCA3B7', 'flamingo',
                                              '#2B1700', 'chocolate',
                                              '#A020F0', 'purple',
                                              '#FC6600', 'orange',
                                              '#FF6347', 'tomato'
                                            ],
                                            custom_colors: true,
                                            fontsize_formats: '8pt 10pt 12pt 14pt 16pt 18pt 24pt 36pt 48pt',
                                            lineheight_formats: '1.1 1.2 1.3 1.4 1.5 2',
                                          }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                        </div>

                        <div className="nk-data data-list">
                          <div className="data-item">
                            <div className="data-col">
                              <div
                                className="form-control-wrap"
                                style={{
                                  width: "100%",
                                  paddingLeft: "80%"
                                }}
                              >
                                <button
                                  className="btn btn-lg btn-primary btn-block"
                                  onClick={(e) => handleCreate(e)}
                                >
                                  {editCase ? "Save" : "Create"}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Toaster position="top-center" reverseOrder={false} duration={3000} />
    </>
  );
};
export default CreateService;
