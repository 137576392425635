import React, { useEffect, useState, useRef, useContext } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useLocation, useNavigate, useParams, Link } from "react-router-dom";
import {
  getSingleAPI,
  postAPI,
  putAPI,
  getGradeListAPI,
  getSubjectListAPI,
  getJobTypeList,
  getLanguageListAPI,
  getCountryListAPI
} from "./Action";
import { Editor } from "@tinymce/tinymce-react";
import RenderLoader from "../../PanelComponent/RenderLoader";
import { getCookies } from "../../Utils/cookies";
import { postMethod, getMethod } from "../../Utils/Service";
import Select from "react-select";
import moment from "moment";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { InputValidationContext } from "../../Components/Context/InputValidationContext";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}
const CreateTutor = () => {
  const {
    handleAlphabetKeyPress,
    handleNumberKeyPress,
    handleAlphaNumKeyPress,
    handleLimitedNumber,
  } = useContext(InputValidationContext);
  const { id } = useParams();
  const query = useQuery();
  const editorRef = useRef(null);
  const history = useNavigate();
  const [editCase, setEditCase] = useState(false);
  const imageRef1 = useRef();
  const imageRef2 = useRef();

  const [values, setValues] = useState({ role: 1, active: true });
  const [loader, setLoader] = useState(false);

  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);


  const handleChange = (e) => {
    e.preventDefault();
    let name = e.target.name;
    let val = e.target.value;
    setValues({ ...values, [name]: val });
  };

  const handleCreate = async (e) => {
    e.preventDefault();
    setLoader(true);

    if (
      values.name != "" &&
      values.email != "" &&
      values.password != "" &&
      values.mobile != ""
    ) {
      if (editCase) {
        putAPI(id, values).then((response) => {
          if (response.status) {
            toast.success(response.message);
            setEditCase(false);
            setLoader(false);
            history("/admin/tutor");
          } else {
            toast.error(response.message);
            setLoader(false);
          }
        });
      } else {
        postAPI(values).then((response) => {
          if (response.status) {
            toast.success(response.message);
            setEditCase(false);
            setLoader(false);
            history("/admin/tutor");
          } else {
            toast.error(response.message);
            setLoader(false);
          }
        });
      }
    } else {
      toast.error("Please fill all required field!");
      setLoader(false);
    }
  };

  // ========multi select============

  const [gradeOption, setGradeOption] = useState([]);
  const [gradeSelect, setGradeSelect] = useState([]);

  const [subjectOption, setSubjectOption] = useState([]);
  const [subjectSelect, setSubjectSelect] = useState([]);

  const [languageOption, setLanguageOption] = useState([]);
  const [languageSelect, setLanguageSelect] = useState([]);


  const [cityOption, setCityOption] = useState([]);
  const [citySelect, setCitySelect] = useState([]);

  const [jobOption, setJobOption] = useState([]);
  const [jobSelect, setJobSelect] = useState([]);

  // const handleSubject = async (selectedValues) => {
  //   let subject = [];
  //   if (selectedValues?.length > 0) {
  //     selectedValues.map((item, index) => {
  //       subject.push(item.value);
  //     });
  //   }
  //   setSubjectSelect(selectedValues);
  //   setValues({ ...values, ["target_subject"]: subject });
  // };

  const handleLanguage = async (selectedValues) => {
    let language = [];
    if (selectedValues?.length > 0) {
      selectedValues.map((item, index) => {
        language.push(item.value);
      });
    }
    setLanguageSelect(selectedValues);
    setValues({ ...values, ["language"]: language });
  };


  const handleJob = async (selectedValues) => {
    let job = [];
    if (selectedValues?.length > 0) {
      selectedValues.map((item, index) => {
        job.push(item.value);
      });
    }
    setJobSelect(selectedValues);
    setValues({ ...values, ["job_type"]: job });
  };

  const handleMultipleCity = async (selectedValues) => {
    let city = [];
    if (selectedValues?.length > 0) {
      selectedValues.map((item, index) => {
        city.push(item.value);
      });
    }
    setCitySelect(selectedValues);
    setValues({ ...values, ["city"]: city });
  };


  const handleCity = async (country) => {
    if (country != "" && country != null) {
      await getMethod("common/city/" + country).then(async (response) => {
        if (response?.status) {
          setCityList(response.data);
          // setCitySelect([]);
          // const citySelect = await makeReactSelect(response.data);
          // setCityOption(citySelect);

          setLoader(false);
        } else {
          toast.error(response.message);
          setLoader(false);
        }
      });
    } else {
      setCityOption([]);
      setCitySelect([]);
      setCityList([]);
    }
  };

  const handleEditCity = async (country) => {

    if ((country != "") && (typeof country !== "undefined") && country != null) {
      await getMethod("common/city/" + country).then(async (response) => {
        if (response?.status) {
          setCityList(response.data);
          // const citySelect = await makeReactSelect(response.data);
          // setCityOption(citySelect);

          setLoader(false);
        } else {
          toast.error(response.message);
          setLoader(false);
        }
      });
    } else {
      // setCityOption([]);
      // setCitySelect([]);
      setCityList([]);
    }
  };

  const handleGrade = async (selectedValues) => {
    let grade = [];
    if (selectedValues?.length > 0) {
      selectedValues.map((item, index) => {
        grade.push(item.value);
      });
    }
    setGradeSelect(selectedValues);
    setValues({ ...values, ["target_student"]: grade });
  };

  const fetchData = async () => {
    if (getCookies("_isJwt") === "true" ? true : false) {
      if (window.location.pathname === "/admin/tutor-edit/" + id && id != "") {
        setLoader(true);
        await getSingleAPI(id).then(async (response) => {
          if (response.status) {
            const data = response?.data;
            if (data) {
              setEditCase(true);
              setValues(data);

              //--subject edit--
              // const subjectSelect = await makeReactSelect(data?.target_subject);
              // setSubjectSelect(subjectSelect);
              //--grade edit--
              const gradeSelect = await makeReactSelect(data?.target_student);
              setGradeSelect(gradeSelect);
              //--job type edit--
              const jobSelect = await makeArrayReactSelect(data?.job_type);
              setJobSelect(jobSelect);
              //--subject edit--
              const languageSelect = await makeReactSelect(data?.language);
              setLanguageSelect(languageSelect);
              //----handeCity----//
              handleEditCity(data?.country);
              // const citySelect = await makeReactSelect(data?.city);
              // setCitySelect(citySelect);
              setLoader(false);
            } else {
              toast.error("some error occured", response.message);
              // history('/404');
            }
          } else {
            toast.error("some error occured", response.message);
            // history('/404');
          }
        });
      }

      await getJobTypeList().then(async (response) => {
        if (response?.status) {
          const jobSelect = await makeArrayReactSelect(response.data);
          setJobOption(jobSelect);
        }
      });

      await getSubjectListAPI().then(async (response) => {
        if (response?.status) {
          // const subjectSelect = await makeReactSelect(response.data);
          setSubjectOption(response.data);
        }
      });

      await getLanguageListAPI().then(async (response) => {
        if (response?.status) {
          const languageSelect = await makeReactSelect(response.data);
          setLanguageOption(languageSelect);
        }
      });

      await getCountryListAPI().then(async (response) => {
        if (response?.status) {
          setCountryList(response.data);
        }
      });

      await getGradeListAPI().then(async (response) => {
        if (response?.status) {
          const gradeSelect = await makeReactSelect(response.data);
          setGradeOption(gradeSelect);
        }
      });

    }
  };

  const makeReactSelect = async (data) => {
    let arr = [];
    data.map((item, index) => {
      const obj = { label: item?.name, key: index, value: item?._id };
      arr.push(obj);
    });
    return arr;
  };

  const makeArrayReactSelect = async (data) => {
    let arr = [];
    data.map((item, index) => {
      const obj = { label: item, key: index, value: item };
      arr.push(obj);
    });
    return arr;
  };

  useEffect(() => {
    window.scroll(0, 0);
    fetchData();
  }, []);
  return (
    <>
      <div className="nk-content">
        <div className="container-fluid">
          <div className="nk-content-inner">
            <div className="nk-content-body">
              <div className="nk-block">
                <div className="card card-bordered">
                  <div className="card-aside-wrap">
                    <div className="card-inner card-inner-lg">
                      <div className="nk-block-head nk-block-head-lg">
                        <div className="nk-block-head-content">
                          <h4 className="nk-block-title">
                            {editCase ? "Edit Tutor" : "Add Tutor"}
                          </h4>
                        </div>
                      </div>
                      {loader ? <RenderLoader trans={true} /> : null}
                      <div className="nk-block">
                        <div className="nk-data data-list">
                          <div className="data-head">
                            <h6 className="overline-title">Normal Details</h6>
                          </div>


                          {/* here image,video,document section */}

                          <div className="data-item">
                            <div className="data-col">
                              <span className="data-label">Profile Image*</span>
                              <div
                                className="form-control-wrap"
                                style={{ width: "100%" }}
                              >
                                {(editCase && values.image) ||
                                  values.image ? (
                                  <Link
                                    to={
                                      process.env.REACT_APP_URL +
                                      "/" +
                                      values?.image
                                    }
                                    download
                                    target="_blank"
                                  >
                                    <img
                                      style={{
                                        maxWidth: "200px",
                                        maxHeight: "150px",
                                      }}
                                      src={
                                        process.env.REACT_APP_IMG_URL +
                                        "/" +
                                        values.image
                                      }
                                      className="img-fluid"
                                    />
                                  </Link>
                                ) : null}

                                <input
                                  type="file"
                                  name="image"
                                  ref={imageRef1}
                                  className="form-control-lg"
                                  style={{ width: "50%" }}
                                  accept="image/png, image/gif, image/jpeg"
                                  onChange={async (event) => {
                                    var file = event.target.files[0];
                                    var formData = new FormData();
                                    formData.append("image", file, file.name);
                                    setLoader(true);

                                    await postMethod(
                                      "uploadImage",
                                      formData
                                    ).then((response) => {
                                      if (response?.status) {
                                        setValues({
                                          ...values,
                                          ["image"]: response?.file,
                                        });
                                        setLoader(false);
                                      } else {
                                        toast.error(response.message);
                                        setLoader(false);
                                      }
                                    });
                                  }}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="data-item">
                            <div className="data-col">
                              <span className="data-label">User Name*</span>
                              <div
                                className="form-control-wrap"
                                style={{ width: "100%" }}
                              >
                                <input
                                  type="text"
                                  value={
                                    values.name
                                  }
                                  onChange={handleChange}
                                  name="name"
                                  placeholder="Enter user name"
                                  required
                                  className="form-control form-control-lg"
                                  style={{ width: "100%" }}
                                  maxLength={50}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="data-item">
                            <div className="data-col">
                              <span className="data-label">Mobile*</span>
                              <div
                                className="form-control-wrap"
                                style={{ width: "100%" }}
                              >
                                <div
                                  className="form-control-wrap"
                                  style={{ width: "100%" }}
                                >
                                  <PhoneInput
                                    inputProps={{
                                      name: "mobile",
                                      required: true,
                                      type: "tel",
                                    }}
                                    onChange={(e, data) => {
                                      if (e != "") {
                                        data?.format
                                          ? data?.format[0]
                                            ? setValues({
                                              ...values,
                                              ["mobile"]: data?.format[0] + e,
                                            })
                                            : ""
                                          : "";
                                      } else {
                                        setValues({
                                          ...values,
                                          ["mobile"]: "",
                                        });
                                      }
                                    }}
                                    className="inputFields"
                                    autocomplete="off"
                                    country={"hk"}
                                    value={values.mobile ? values.mobile : ""}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="data-item">
                            <div className="data-col">
                              <span className="data-label">Email*</span>
                              <div
                                className="form-control-wrap"
                                style={{ width: "100%" }}
                              >
                                <input
                                  type="email"
                                  value={values.email ? values.email : ""}
                                  onChange={handleChange}
                                  name="email"
                                  placeholder="Enter email address"
                                  required
                                  className="form-control form-control-lg"
                                  style={{ width: "100%" }}
                                  maxLength={50}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="data-item">
                            <div className="data-col">
                              <span className="data-label">Password*</span>
                              <div
                                className="form-control-wrap"
                                style={{ width: "100%" }}
                              >
                                <input
                                  type="text"
                                  value={values.password ? values.password : ""}
                                  onChange={handleChange}
                                  name="password"
                                  placeholder="Enter password"
                                  required
                                  className="form-control form-control-lg"
                                  style={{ width: "100%" }}
                                  maxLength={20}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="data-item">
                            <div className="data-col">
                              <span className="data-label">Gender</span>
                              <div
                                className="form-control-wrap"
                                style={{ width: "100%" }}
                              >
                                <select
                                  value={values.gender}
                                  onChange={handleChange}
                                  name="gender"
                                  required
                                  className="form-control form-control-lg"
                                  style={{ width: "100%" }}
                                >
                                  <option value="">Select gender </option>
                                  <option value={"male"} key={"male"}>
                                    Male{" "}
                                  </option>
                                  <option value={"female"} key={"female"}>
                                    Female
                                  </option>
                                </select>
                              </div>
                            </div>
                          </div>


                          <div className="data-item">
                            <div className="data-col">
                              <span className="data-label">Profession</span>
                              <div
                                className="form-control-wrap"
                                style={{ width: "100%" }}
                              >
                                <input
                                  type="profession"
                                  value={values.profession ? values.profession : ""}
                                  onChange={handleChange}
                                  name="profession"
                                  placeholder="Enter your profession "
                                  required
                                  className="form-control form-control-lg"
                                  style={{ width: "100%" }}
                                  maxLength={50}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="data-item">
                            <div className="data-col">
                              <span className="data-label">
                                Age
                              </span>
                              <div
                                className="form-control-wrap"
                                style={{ width: "100%" }}
                              >
                                <input
                                  type="text"
                                  value={
                                    values.age
                                      ? values.age
                                      : ""
                                  }
                                  onChange={handleChange}
                                  name="age"
                                  placeholder="Enter user age"
                                  required
                                  className="form-control form-control-lg"
                                  style={{ width: "100%" }}
                                  onKeyDown={handleLimitedNumber}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="data-item">
                            <div className="data-col">
                              <span className="data-label">
                                Total Experience in years
                              </span>
                              <div
                                className="form-control-wrap"
                                style={{ width: "100%" }}
                              >
                                <input
                                  type="text"
                                  value={
                                    values.totol_experience
                                      ? values.totol_experience
                                      : ""
                                  }
                                  onChange={handleChange}
                                  name="totol_experience"
                                  placeholder="Enter total experience in years"
                                  required
                                  className="form-control form-control-lg"
                                  style={{ width: "100%" }}
                                  onKeyDown={handleLimitedNumber}
                                />
                              </div>
                            </div>
                          </div>


                          <div className="data-item">
                            <div className="data-col">
                              <span className="data-label">Eduction</span>
                              <div
                                className="form-control-wrap"
                                style={{ width: "100%" }}
                              >
                                <input
                                  type="education"
                                  value={values.education ? values.education : ""}
                                  onChange={handleChange}
                                  name="education"
                                  placeholder="Enter your qualification "
                                  required
                                  className="form-control form-control-lg"
                                  style={{ width: "100%" }}
                                  maxLength={50}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="data-item">
                            <div className="data-col">
                              <span className="data-label">Target Subject {values.country}</span>
                              <div
                                className="form-control-wrap"
                                style={{ width: "100%" }}
                              >
                                <select
                                  value={values.target_subject ? values.target_subject : ""}
                                  onChange={(e) => {
                                    handleChange(e);
                                  }}
                                  name="target_subject"
                                  required
                                  className="form-control form-control-lg"
                                  style={{ width: "100%" }}
                                >
                                  <option value="">Select an subject</option>
                                  {subjectOption?.length
                                    ? subjectOption?.map((n, index) => (
                                      <option value={n?._id} key={index}>
                                        {n?.name ? n?.name : ""}
                                      </option>
                                    ))
                                    : null}
                                </select>

                                {/* <Select
                                  value={subjectSelect}
                                  onChange={handleSubject}
                                  closeMenuOnSelect={false}
                                  options={subjectOption}
                                  isMulti
                                  name="subject"
                                /> */}
                              </div>
                            </div>
                          </div>


                          <div className="data-item">
                            <div className="data-col">
                              <span className="data-label">
                                Target Student
                              </span>
                              <div
                                className="form-control-wrap"
                                style={{ width: "100%" }}
                              >

                                <Select
                                  value={gradeSelect}
                                  onChange={handleGrade}
                                  closeMenuOnSelect={false}
                                  options={gradeOption}
                                  isMulti
                                  name="grade"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="data-item">
                            <div className="data-col">
                              <div
                                className="form-control-wrap"
                                style={{ width: "100%" }}
                              >
                                <div className="data-item">
                                  <div className="data-col">
                                    <span className="data-label">Bio</span>
                                    <div
                                      className="form-control-wrap"
                                      style={{ width: "100%" }}
                                    >
                                      <Editor
                                        className="editorStyle"
                                        outputFormat="text"
                                        onInit={(evt, editor) =>
                                          (editorRef.current = editor)
                                        }
                                        onEditorChange={(evt, editor) => {
                                          setValues({
                                            ...values,
                                            ["intro"]:
                                              editorRef.current.getContent(),
                                          });
                                        }}
                                        value={values ? values.intro : ""}
                                        apiKey={
                                          process.env.REACT_APP_TINMCY_KEY
                                        }
                                        init={{
                                          width: "100%",
                                          height: 410,
                                          skin: "naked",
                                          menubar: false,
                                          selector: "textarea",
                                          resize: "both",
                                          branding: false,
                                          draggable_modal: false,
                                          statusbar: false,
                                          theme: "silver",
                                          draggable_modal: false,
                                          toolbar_mode: "floating",
                                          toolbar_location: "bottom",
                                          placeholder:
                                            "Start typing discription here",
                                          plugins: [],
                                          toolbar:
                                            "fontsizeselect fontDecoration alignment forecolor emoticons",
                                          toolbar_groups: {
                                            fontDecoration: {
                                              icon: "bold",
                                              tooltip: "Font Decoration",
                                              items: "bold italic underline",
                                            },
                                            alignment: {
                                              icon: "align-left",
                                              tooltip:
                                                "Alignment | Line Height",
                                              items:
                                                "alignleft aligncenter alignright alignjustify lineheight",
                                            },
                                          },
                                          textcolor_map: [
                                            "#000000",
                                            "Black",
                                            "#FF0000",
                                            "red",
                                            "#008000",
                                            "green",
                                            "#0000FF",
                                            "blue",
                                            "#FC0FC0",
                                            "pink",
                                            "#B200ED",
                                            "violet",
                                            "#FF0090",
                                            "magenta",
                                            "#2E8B57",
                                            "sea",
                                            "#FCA3B7",
                                            "flamingo",
                                            "#2B1700",
                                            "chocolate",
                                            "#A020F0",
                                            "purple",
                                            "#FC6600",
                                            "orange",
                                            "#FF6347",
                                            "tomato",
                                          ],
                                          color_map: [
                                            "#000000",
                                            "Black",
                                            "#FF0000",
                                            "red",
                                            "#008000",
                                            "green",
                                            "#0000FF",
                                            "blue",
                                            "#FC0FC0",
                                            "pink",
                                            "#B200ED",
                                            "violet",
                                            "#FF0090",
                                            "magenta",
                                            "#2E8B57",
                                            "sea",
                                            "#FCA3B7",
                                            "flamingo",
                                            "#2B1700",
                                            "chocolate",
                                            "#A020F0",
                                            "purple",
                                            "#FC6600",
                                            "orange",
                                            "#FF6347",
                                            "tomato",
                                          ],
                                          custom_colors: true,
                                          fontsize_formats:
                                            "8pt 10pt 12pt 14pt 16pt 18pt 24pt 36pt 48pt",
                                          lineheight_formats:
                                            "1.1 1.2 1.3 1.4 1.5 2",
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>


                          <div className="data-item">
                            <div className="data-col">
                              <span className="data-label">Language</span>
                              <div
                                className="form-control-wrap"
                                style={{ width: "100%" }}
                              >
                                <Select
                                  value={languageSelect}
                                  onChange={handleLanguage}
                                  closeMenuOnSelect={false}
                                  options={languageOption}
                                  isMulti
                                  name="language"
                                />
                              </div>
                            </div>
                          </div>


                          <div className="data-item">
                            <div className="data-col">
                              <span className="data-label">Job Type</span>
                              <div
                                className="form-control-wrap"
                                style={{ width: "100%" }}
                              >
                                <Select
                                  value={jobSelect}
                                  onChange={handleJob}
                                  closeMenuOnSelect={false}
                                  options={jobOption}
                                  isMulti
                                  name="job"
                                />
                              </div>
                            </div>
                          </div>


                          <div className="data-item">
                            <div className="data-col">
                              <span className="data-label">
                                Fee's
                              </span>
                              <div
                                className="form-control-wrap"
                                style={{ width: "100%" }}
                              >
                                <input
                                  type="text"
                                  value={
                                    values.fees
                                      ? values.fees
                                      : ""
                                  }
                                  onChange={handleChange}
                                  name="fees"
                                  placeholder="Enter tutor fees"
                                  required
                                  className="form-control form-control-lg"
                                  style={{ width: "100%" }}
                                  onKeyDown={handleLimitedNumber}
                                />
                              </div>
                            </div>
                          </div>


                          <div className="data-item">
                            <div className="data-col">
                              <span className="data-label">Is Active</span>
                              <div
                                className="form-control-wrap"
                                style={{ width: "100%" }}
                              >
                                <select
                                  value={values.active}
                                  onChange={handleChange}
                                  name="active"
                                  required
                                  className="form-control form-control-lg"
                                  style={{ width: "100%" }}
                                >
                                  <option value={true}>Active </option>
                                  <option value={false}>In Active</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>



                        <div className="data-item">
                          <div className="data-col">
                            <span className="data-label">Country</span>
                            <div
                              className="form-control-wrap"
                              style={{ width: "100%" }}
                            >
                              <select
                                value={values.country ? values.country : null}
                                onChange={(e) => {
                                  handleChange(e);
                                  handleCity(e.target.value);
                                }}
                                name="country"
                                required
                                className="form-control form-control-lg"
                                style={{ width: "100%" }}
                              >
                                <option value="">Select an country</option>
                                {countryList?.length
                                  ? countryList?.map((n, index) => (
                                    <option value={n?._id} key={index}>
                                      {n?.name ? n?.name : ""}
                                    </option>
                                  ))
                                  : null}
                              </select>
                            </div>
                          </div>
                        </div>


                        <div className="data-item">
                          <div className="data-col">
                            <span className="data-label">City</span>
                            <div
                              className="form-control-wrap"
                              style={{ width: "100%" }}
                            >
                              <select
                                value={values.city ? values.city : null}
                                onChange={handleChange}
                                name="city"
                                required
                                className="form-control form-control-lg"
                                style={{ width: "100%" }}
                              >
                                <option value="">Select an city</option>
                                {cityList?.length
                                  ? cityList?.map((n, index) => (
                                    <option value={n?._id} key={index}>
                                      {n?.name ? n?.name : ""}
                                    </option>
                                  ))
                                  : null}
                              </select>
                            </div>
                          </div>
                        </div>


                        <div className="data-item">
                          <div className="data-col">
                            <span className="data-label">Location</span>
                            <div
                              className="form-control-wrap"
                              style={{ width: "100%" }}
                            >
                              <input
                                type="location"
                                value={values.location ? values.location : ""}
                                onChange={handleChange}
                                name="location"
                                placeholder="Enter your location"
                                required
                                className="form-control form-control-lg"
                                style={{ width: "100%" }}
                                maxLength={50}
                              />
                            </div>
                          </div>
                        </div>



                        {/* <div className="data-item">
                          <div className="data-col">
                            <span className="data-label">City</span>
                            <div
                              className="form-control-wrap"
                              style={{ width: "100%" }}
                            >
                              <Select
                                value={citySelect}
                                onChange={handleMultipleCity}
                                closeMenuOnSelect={false}
                                options={cityOption}
                                isMulti
                                name="city"
                              />
                            </div>
                          </div>
                        </div> */}


                        {/* <div className="data-item">
                          <div className="data-col">
                            <span className="data-label">Location</span>
                            <div
                              className="form-control-wrap"
                              style={{ width: "100%" }}
                            >
                              <input
                                type="location"
                                value={values.location ? values.location : ""}
                                onChange={handleChange}
                                name="location"
                                placeholder="Enter your location "
                                required
                                className="form-control form-control-lg"
                                style={{ width: "100%" }}
                                maxLength={50}
                              />
                            </div>
                          </div>
                        </div> */}



                        <div className="data-item">
                          <div className="data-col">
                            <span className="data-label">Document</span>
                            <div
                              className="form-control-wrap"
                              style={{ width: "100%" }}
                            >
                              {(editCase && values.document) ||
                                values.document ? (
                                <Link
                                  to={
                                    process.env.REACT_APP_URL +
                                    "/" +
                                    values?.document
                                  }
                                  download
                                  target="_blank"
                                >
                                  {/* <img
                                    style={{
                                      maxWidth: "200px",
                                      maxHeight: "150px",
                                    }}
                                    src={
                                      process.env.REACT_APP_IMG_URL +
                                      "/" +
                                      values.document
                                    }
                                    className="img-fluid"
                                  /> */}VIEW
                                </Link>
                              ) : null}

                              <input
                                type="file"
                                name="document"
                                ref={imageRef2}
                                className="form-control-lg"
                                style={{ width: "50%" }}
                                accept=""
                                onChange={async (event) => {
                                  var file = event.target.files[0];
                                  var formData = new FormData();
                                  formData.append("image", file, file.name);
                                  setLoader(true);

                                  await postMethod(
                                    "uploadImage",
                                    formData
                                  ).then((response) => {
                                    if (response?.status) {
                                      setValues({
                                        ...values,
                                        ["document"]: response?.file,
                                      });
                                      setLoader(false);
                                    } else {
                                      toast.error(response.message);
                                      setLoader(false);
                                    }
                                  });
                                }}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="nk-data data-list">
                          <div className="data-item">
                            <div className="data-col">
                              <div
                                className="form-control-wrap"
                                style={{
                                  width: "100%",
                                  paddingLeft: "80%"
                                }}
                              >
                                <button
                                  className="btn btn-lg btn-primary btn-block"
                                  onClick={(e) => handleCreate(e)}
                                >
                                  {editCase ? "Save" : "Create"}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Toaster position="top-center" reverseOrder={false} duration={3000} />
    </>
  );
};
export default CreateTutor;
