import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { setCookies, getCookies } from "../../Utils/cookies";
import { getMethod, setAuthorizationToken } from "../../Utils/Service";
import { MdSettingsSuggest, MdOutlineEqualizer } from "react-icons/md";

const Topbar = () => {
  const history = useNavigate();
  const [loader, setLoader] = useState(true);
  const [dataList, setDataList] = useState([]);
  const [profileDetails, setProfileDetails] = useState({
    firstName: "I",
    lastName: "A",
    email: "",
  });


  const handleLogout = async (e) => {
    setCookies("_isJwt", false);
    setAuthorizationToken(false);
    setCookies("admin_details", "");
    sessionStorage.removeItem("token");
    window.open("/", "_self");
  };

  useEffect(() => {
    if (getCookies("_isJwt") === "true") {
      setAuthorizationToken(true);
      // console.log("********top**********");
      getMethod("auth/getUser").then((response) => {
        // console.log("********top response**********",response);
        if (response.status) {
          setCookies("_isJwt", true);
          setAuthorizationToken(true);
          setProfileDetails(response.data);
          sessionStorage.setItem("role", response.data.role);
        } else {
          setCookies("_isJwt", false);
          setAuthorizationToken(false);
          setCookies("admin_details", "");
          sessionStorage.removeItem("role");
        }
      });
    }
  }, []);
  return (
    <div className="sideBarUL nk-header nk-header-fixed is-light">
      <div className="container-fluid">
        <div className="nk-header-wrap">
          <div className="nk-menu-trigger d-xl-none ml-n1">
            <Link
              to="#"
              className="nk-nav-toggle nk-quick-nav-icon"
              data-target="sidebarMenu"
            >
              <em className="icon ni ni-menu"></em>
            </Link>
          </div>
          <div className="nk-header-brand d-xl-none">
            <Link to="/" className="logo-link">
              <img
                className="logo-light logo-img"
                src={"/assets/images/logo.svg"}
                alt="logo"
              />
              <img
                className="logo-dark logo-img"
                src={"/assets/images/logo.svg"}
                srcSet={"/assets/images/logo.svg"}
                alt="logo-dark"
              />
            </Link>
          </div>

          <div className="nk-header-tools">
            <ul className="nk-quick-nav">
              <li className="dropdown user-dropdown">
                <Link
                  to="#"
                  style={{ textDecoration: "none" }}
                  className="dropdown-toggle"
                  data-toggle="dropdown"
                >
                  <div className="user-toggle">
                    <div className="user-avatar sm">
                      <span style={{ textTransform: "uppercase" }}>
                        {profileDetails?.name ? profileDetails?.name[0] : ""}
                      </span>
                    </div>
                    <div className="user-info d-none d-md-block">
                      {/* <div
                        className="user-status"
                        style={{ textTransform: "capitalize" }}
                      ></div> */}
                      <div className="user-name dropdown-indicator">
                        {profileDetails?.name ? profileDetails?.name.toUpperCase() : ""}
                      </div>
                    </div>
                  </div>
                </Link>

                <div className="dropdown-menu dropdown-menu-md dropdown-menu-right dropdown-menu-s1">
                  <div className="dropdown-inner user-card-wrap bg-lighter d-none d-md-block">
                    <div className="user-card">
                      <div className="user-avatar">
                        <span style={{ textTransform: "uppercase" }}>
                          {profileDetails?.name ? profileDetails?.name[0] : ""}
                        </span>
                      </div>
                      <div className="user-info">
                        <span className="lead-text">
                          {profileDetails?.name ? profileDetails?.name.toUpperCase() : ""}
                        </span>
                        <span className="sub-text">
                          {profileDetails?.email ? profileDetails?.email : ""}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="dropdown-inner">
                    <ul className="link-list">
                      <li><Link to="/admin/changePassword">
                        <span className="nk-menu-text">
                          <MdSettingsSuggest />
                          Change Password
                        </span>
                      </Link>
                      </li>
                      <li><Link to="/admin/updatePercentage">
                        <span className="nk-menu-text">
                          <MdOutlineEqualizer />
                          Update Percentage
                        </span>
                      </Link>
                      </li>
                    </ul>
                  </div>

                  <div className="dropdown-inner">
                    <ul className="link-list">
                      <li>
                        <Link
                          style={{ cursor: "pointer" }}
                          onClick={handleLogout}
                        >
                          <em className="icon ni ni-signout"></em>
                          <span>Sign out</span>
                        </Link>
                      </li>
                    </ul>
                  </div>

                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Topbar;
