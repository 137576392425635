import React from "react";
import { Link, useLocation } from "react-router-dom";
import {
  MdOutlineSettingsInputComposite,
  MdLanguage,
  MdOutlineSubject,
  MdClass,
  MdSupervisedUserCircle,
  MdVerifiedUser,
  MdMap,
  MdOutlineSubscriptions,
  MdDoorbell,
  MdAttachMoney
} from "react-icons/md";
import {
  FaUsers,
  FaUser,
  FaHome,
} from "react-icons/fa";
import { AiOutlineSchedule, AiOutlineTeam } from "react-icons/ai";
import { getCookies, setCookies } from "../../Utils/cookies";


const Sidebar = () => {
  const location = useLocation();

  return (
    <div
      className="sideBarUL nk-sidebar nk-sidebar-fixed is-dark "
      data-content="sidebarMenu"
    >
      <div
        className="nk-sidebar-element nk-sidebar-head"
        style={{ backgroundColor: "#fff" }}
      >
        <div className="nk-sidebar-brand" style={{ backgroundColor: "#fff" }}>
          <Link to="/" className="logo-link nk-sidebar-logo">
            <img
              className="logo-light logo-img"
              src={"/assets/images/logo.svg"}
              alt="logo"
            />
            <img
              className="logo-dark logo-img"
              src={"/assets/images/logo.svg"}
              srcSet={"/assets/images/logo.svg"}
              alt="logo-dark"
            />
          </Link>
        </div>
        <div className="nk-menu-trigger mr-n2">
          <Link
            to="#"
            className="nk-nav-toggle nk-quick-nav-icon d-xl-none"
            data-target="sidebarMenu"
          >
            <em className="icon ni ni-arrow-left"></em>
          </Link>
        </div>
      </div>
      <div className="nk-sidebar-element">
        <div className="nk-sidebar-content">
          <div className="nk-sidebar-menu" data-simplebar>
            <ul className="nk-menu">
              <li className={location?.pathname == '/admin/dashboard' ? 'active' : '' + "nk-menu-item has-sub"}>
                <Link
                  to="/admin/dashboard"
                  className="nk-menu-link active"
                >
                  <span className="nk-menu-text">
                    <FaHome />
                    Dashboard
                  </span>
                </Link>
              </li>

              <li className={(location?.pathname == '/admin/student'|| location?.pathname == '/admin/student-edit/*' || location?.pathname == '/admin/student-create') ? 'active' : '' + "nk-menu-item has-sub"}>
                <Link
                  to="/admin/student"
                  className="nk-menu-link"
                >
                  <span className="nk-menu-text">
                    <MdSupervisedUserCircle />
                    Student Manager
                  </span>
                </Link>
              </li>

              <li className={location?.pathname == '/admin/tutor' ? 'active' : '' + "nk-menu-item has-sub"}>
                <Link
                  to="/admin/tutor"
                  className="nk-menu-link"
                >
                  <span className="nk-menu-text">
                    <MdVerifiedUser />
                    Tutor Manager
                  </span>
                </Link>
              </li>

              <li className={location?.pathname == '/admin/plan' ? 'active' : '' + "nk-menu-item has-sub"}>
                <Link
                  to="/admin/plan"
                  className="nk-menu-link"
                >
                  <span className="nk-menu-text">
                    <MdOutlineSubscriptions />
                    Plan Manager
                  </span>
                </Link>
              </li>


              <li className={location?.pathname == '/admin/finance' ? 'active' : '' + "nk-menu-item has-sub"}>
                <Link
                  to="/admin/finance"
                  className="nk-menu-link"
                >
                  <span className="nk-menu-text">
                    <MdDoorbell />
                    Subscribers
                  </span>
                </Link>
              </li>
              
              <li className={location?.pathname == '/admin/payment' ? 'active' : '' + "nk-menu-item has-sub"}>
                <Link
                  to="/admin/payment"
                  className="nk-menu-link"
                >
                  <span className="nk-menu-text">
                    <MdAttachMoney />
                    Payment History
                  </span>
                </Link>
              </li>


              <li className={location?.pathname == '/admin/language' ? 'active' : '' + "nk-menu-item has-sub"}>
                <Link
                  to="/admin/language"
                  className="nk-menu-link"
                >
                  <span className="nk-menu-text">
                    <MdLanguage />
                    Language Manager
                  </span>
                </Link>
              </li>

              <li className={location?.pathname == '/admin/subject' ? 'active' : '' + "nk-menu-item has-sub"}>
                <Link
                  to="/admin/subject"
                  className="nk-menu-link"
                >
                  <span className="nk-menu-text">
                    <MdOutlineSubject />
                    Subject Manager
                  </span>
                </Link>
              </li>

              <li className={location?.pathname == '/admin/grade' ? 'active' : '' + "nk-menu-item has-sub"}>
                <Link
                  to="/admin/grade"
                  className="nk-menu-link"
                >
                  <span className="nk-menu-text">
                    <MdClass />
                    Grade Manager
                  </span>
                </Link>
              </li>

              <li className={location?.pathname == '/admin/service' ? 'active' : '' + "nk-menu-item has-sub"}>
                <Link
                  to="/admin/service"
                  className="nk-menu-link"
                >
                  <span className="nk-menu-text">
                    <MdOutlineSettingsInputComposite />
                    Page Manager
                  </span>
                </Link>
              </li>


              <li className={location?.pathname == '/admin/country' ? 'active' : '' + "nk-menu-item has-sub"}>
                <Link
                  to="/admin/country"
                  className="nk-menu-link"
                >
                  <span className="nk-menu-text">
                    <MdMap />
                    Country Manager
                  </span>
                </Link>
              </li>

              {
              /*
              sessionStorage.getItem('role') == 1 &&
  
                <li className={location?.pathname=='/admin/finance' ? 'active' : ''+  "nk-menu-item has-sub"}>
                  <Link
                    to="/admin/finance"
                    className="nk-menu-link"
                  >
                    <span className="nk-menu-text">
                      <MdAttachMoney />
                      Finance
                    </span>
                  </Link>
                </li>
              */}


              { /*
              sessionStorage.getItem('role') == 1 &&
                <>
                  <li className={location?.pathname=='/admin/contactus' ? 'active' : ''+ "nk-menu-item has-sub"}>
                    <Link
                      to="/admin/contactus"
                      className="nk-menu-link"
                    >
                      <span className="nk-menu-text">
                        <MdOutlineMobileFriendly />
                        Contact Us
                      </span>
                    </Link>
                  </li>
                  <li className={location?.pathname=='/admin/plan' ? 'active' : ''+ "nk-menu-item has-sub"}>
                    <Link
                      to="/admin/plan"
                      className="nk-menu-link"
                    >
                      <span className="nk-menu-text">
                        <MdOutlineSubscriptions />
                        Subscription Plan
                      </span>
                    </Link>
                  </li>
            </> */}
            </ul>
          </div>
        </div>
      </div>
    </div >
  );
};
export default Sidebar;
