import React, { useEffect, useState, useContext } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import RenderLoader from "../PanelComponent/RenderLoader";
import { updatePercentage, getPercentage } from "../Utils/action";
import { InputValidationContext } from "../Components/Context/InputValidationContext";


const UpdatePercentage = () => {

  const {
    handleAlphabetKeyPress,
    handleNumberKeyPress,
    handleAlphaNumKeyPress,
    handleLimitedNumber,
  } = useContext(InputValidationContext);

  const history = useNavigate();
  const [editCase, setEditCase] = useState(false);
  const [admin, setAdmin] = useState("");
  const [tutor, setTutor] = useState("");
  const [loader, setLoader] = useState(false);

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);
    if (
      admin != "" &&
      tutor != ""
    ) {
      let data = {
        admin: admin,
        tutor: tutor,
      };

      updatePercentage(data).then((response) => {
        if (response.status) {
          toast.success(response.message);
          setLoader(false);
          setAdmin(response?.data?.admin_ratio);
          setTutor(response?.data?.user_ratio);
          // history("/admin");

        } else {
          toast.error(response.message);
          setLoader(false);
        }
      });

    } else {
      toast.error("Please fill all required field!");
      setLoader(false);
    }
  };

  const fetchData = async () => {
    getPercentage().then((response) => {
      if (response.status) {
        setLoader(false);
        setAdmin(response?.data?.admin_ratio);
        setTutor(response?.data?.user_ratio);
      }
    });
  }

  useEffect(() => {

    fetchData();

  }, []);
  return (
    <>
      <div className="nk-content">
        <div className="container-fluid">
          <div className="nk-content-inner">
            <div className="nk-content-body">
              <div className="nk-block">
                <div className="card card-bordered">
                  <div className="card-aside-wrap">
                    <div className="card-inner card-inner-lg">
                      <div className="nk-block-head nk-block-head-lg">
                        <div className="nk-block-head-content">
                          <h4 className="nk-block-title">
                            Update Percentage
                          </h4>
                        </div>
                      </div>
                      {loader ? <RenderLoader trans={true} /> : null}
                      <div className="nk-block">
                        <div className="nk-data data-list">
                          {/* <div className="data-head">
                            <h6 className="overline-title">Normal Details</h6>
                          </div> */}


                          <div className="data-item">
                            <div className="data-col">
                              <span className="data-label">Admin ratio*</span>
                              <div
                                className="form-control-wrap"
                                style={{ width: "100%" }}
                              >
                                <input
                                  type="text"
                                  value={admin}
                                  name="admin"
                                  placeholder="Enter admin percentage"
                                  onChange={(e) => {
                                    setAdmin(e.target.value);
                                  }}
                                  required
                                  className="form-control form-control-lg"
                                  style={{ width: "100%" }}
                                  onKeyDown={handleLimitedNumber}
                                  autocomplete="off"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="data-item">
                            <div className="data-col">
                              <span className="data-label">Tutor ratio*</span>
                              <div
                                className="form-control-wrap"
                                style={{ width: "100%" }}
                              >
                                <input
                                  type="text"
                                  value={tutor}
                                  name="tutor"
                                  placeholder="Enter tutor percentage"
                                  onChange={(e) => {
                                    setTutor(e.target.value);
                                  }}
                                  required
                                  className="form-control form-control-lg"
                                  style={{ width: "100%" }}
                                  onKeyDown={handleLimitedNumber}
                                  autocomplete="off"
                                />
                              </div>
                            </div>
                          </div>


                        </div>

                        <div className="nk-data data-list">
                          <div className="data-item">
                            <div className="data-col">
                              <div
                                className="form-control-wrap"
                                style={{
                                  width: "100%",
                                  paddingLeft: "80%"
                                }}
                              >
                                <button
                                  className="btn btn-lg btn-primary btn-block"
                                  onClick={(e) => handleFormSubmit(e)}
                                >Save</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Toaster position="top-center" reverseOrder={false} duration={3000} />
    </>
  );
};
export default UpdatePercentage;
