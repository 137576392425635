import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route, useLocation, useNavigate } from "react-router-dom";

import NoPage from "../Pages/NoPage";
import NotFound from "../PanelComponent/NotFound";
import Footer from "../Components/Footer/Footer";
import Sidebar from "../Components/Sidebar/Sidebar";
import Topbar from "../Components/Topbar/Topbar";

import Login from "../Pages/Auth/Login/Login";
import Dashboard from "../Pages/Dashboard";
import Reports from "../Pages/Reports";
import ChangePassword from "../Pages/ChangePassword";
import ResetPassword from "../Pages/ResetPassword";
import UpdatePercentage from "../Pages/UpdatePercentage";


//---Contact Us---//
import ContactUs from "../Pages/ContactUs";

//---Language---//
import Language from "../Pages/Language";
import CreateLanguage from "../Pages/Language/CreateLanguage";


//---Subject---//
import Subject from "../Pages/Subject";
import CreateSubject from "../Pages/Subject/CreateSubject";

//---Grade---//
import Grade from "../Pages/Grade";
import CreateGrade from "../Pages/Grade/CreateGrade";

//---Language---//
import Service from "../Pages/Service";
import CreateService from "../Pages/Service/CreateService";

//---Student---//
import Student from "../Pages/Student";
import CreateStudent from "../Pages/Student/CreateStudent";

//---Tutor---//
import Tutor from "../Pages/Tutor";
import CreateTutor from "../Pages/Tutor/CreateTutor";

//---Country---//
import Country from "../Pages/Country";
import CreateCountry from "../Pages/Country/CreateCountry";


//---Cities---//
import City from "../Pages/City";
import CreateCity from "../Pages/City/CreateCity";


//---Cities---//
import Plan from "../Pages/Plan";
import CreatePlan from "../Pages/Plan/CreatePlan";

//---Cities---//
import Finance from "../Pages/Finance";
import Payment from "../Pages/Payment";


import { removeSession } from "../Utils/storage";
import { getCookies, setCookies } from "../Utils/cookies";
import { setAuthorizationToken, getMethod } from "../Utils/Service";

import { InputValidationProvider } from "../Components/Context/InputValidationContext";


const Routing = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [token, setToken] = useState(false);

  const fetchData = async () => {
    try {
      setAuthorizationToken(true);
      await getMethod("auth/getUser").then((response) => {
        if (response.status) {
          setAuthorizationToken(true);
          setCookies("_isJwt", true);
          setToken(true);
          sessionStorage.setItem("role", response.data.role);
          setCookies(
            "admin_details",
            JSON.stringify({
              name: response.data.name,
              email: response.data.email,
              contactNumber: response.data.contactNumber,
              role: response.data.role,
              id: response.data._id,
            })
          );
        } else {
          setAuthorizationToken(false);
          setCookies("_isJwt", false);
          setToken(false);
          setCookies("admin_details", "");
          sessionStorage.removeItem("role");
          navigate('/');
        }
      });
      window.scroll(0, 0);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
    // alert(token);
  }, []);

  return (
    <InputValidationProvider>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route path="/reset/:number/:token" element={<ResetPassword />} />

        {token && (
          <Route
            path="admin/*"
            element={
              <div className="nk-body bg-lighter npc-general has-sidebar ">
                <div className="nk-app-root">
                  <div className="nk-main">
                    <Sidebar />
                    <div className="nk-wrap">
                      <Topbar />
                      <Routes>
                        <Route path="/" element={<Dashboard />} />
                        <Route path="/dashboard" element={<Dashboard />} />
                        <Route path="/changePassword" element={<ChangePassword />} />
                        <Route path="/updatePercentage" element={<UpdatePercentage />} />

                        {sessionStorage.getItem('role') == 1 && (
                          <>
                            {/* Language routes */}
                            <Route path="/language" element={<Language />} />
                            <Route path="/language-create" element={<CreateLanguage />} />
                            <Route path="/language-edit/:id" element={<CreateLanguage />} />

                            {/* subject routes */}
                            <Route path="/subject" element={<Subject />} />
                            <Route path="/subject-create" element={<CreateSubject />} />
                            <Route path="/subject-edit/:id" element={<CreateSubject />} />

                            {/* grade routes */}
                            <Route path="/grade" element={<Grade />} />
                            <Route path="/grade-create" element={<CreateGrade />} />
                            <Route path="/grade-edit/:id" element={<CreateGrade />} />

                            {/* Student type user routes */}
                            <Route path="/student" element={<Student />} />
                            <Route path="/student-create" element={<CreateStudent />} />
                            <Route path="/student-edit/:id" element={<CreateStudent />} />

                            {/* Tutor type user routes */}
                            <Route path="/tutor" element={<Tutor />} />
                            <Route path="/tutor-create" element={<CreateTutor />} />
                            <Route path="/tutor-edit/:id" element={<CreateTutor />} />

                            {/* service routes */}
                            <Route path="/service" element={<Service />} />
                            <Route path="/service-create" element={<CreateService />} />
                            <Route path="/service-edit/:id" element={<CreateService />} />

                            {/* Visa routes */}
                            <Route path="/plan" element={<Plan />} />
                            <Route path="/plan-create" element={<CreatePlan />} />
                            <Route path="/plan-edit/:id" element={<CreatePlan />} />

                            {/* Country routes */}
                            <Route path="/country" element={<Country />} />
                            <Route path="/country-create" element={<CreateCountry />} />
                            <Route path="/country-edit/:id" element={<CreateCountry />} />

                            {/* city routes */}
                            <Route path="/city" element={<City />} />
                            <Route path="/city-create" element={<CreateCity />} />
                            <Route path="/city-edit/:id" element={<CreateCity />} />

                            {/* finance routes */}
                            <Route path="/finance" element={<Finance />} />
                            <Route path="/payment" element={<Payment />} />
                          </>
                        )}
                      </Routes>
                      <Footer />
                    </div>
                  </div>
                </div>
              </div>
            }
          />
        )}

        <Route path="*" element={<NoPage />} />
      </Routes>
    </InputValidationProvider>
  );
};

export default Routing;
