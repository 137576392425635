import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { postMethod } from "../Utils/Service";
import { getSessionStorage } from "../Utils/storage";
import toast, { Toaster } from "react-hot-toast";
import RenderLoader from "../PanelComponent/RenderLoader";
import { setCookies } from "../Utils/cookies";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}
const ResetPassword = () => {
  const { number, token } = useParams();
  const history = useNavigate();
  const query = useQuery();
  const [loader, setLoader] = useState(true);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [enableInputBox, setEnableInputBox] = useState(true);

  const handleResetPassword = async (e) => {
    setLoader(true);
    e.preventDefault();
    if (email === "") {
      toast.error("Please enter new password");
    } else {
      setLoader(false);
      const data = {
        password: password,
        email: email
      };

      await postMethod("auth/resetPassword", data).then((response) => {
        if (response.status) {
          toast.success(response.message);
          setTimeout(() => {
            history('/');
          }, 3000);
        } else {
          toast.error(response.message);
          setLoader(false);
        }
      });
    }
    setLoader(false);
  };

  const checkResetExpiryTokenForAdmin = async () => {
    try {
      const data = {
        "token": number,
        "email": token
      }

      await postMethod("auth/checkResetExpiryTokenForAdmin", data).then((response) => {
        if (response.status) {
          // toast.success(response.message);
          setEnableInputBox(false)
          setEmail(token);
        } else {
          toast.error("Reset password token is expired... please try again");
          setLoader(false);
          setTimeout(() => {
            history('/');
          }, 4000);
        }
      });
    }
    catch (error) {
      console.error(error);
      toast.error(error.message);
    }
  };

  useEffect(() => {
    setLoader(false);
    checkResetExpiryTokenForAdmin();
  }, []);

  return (
    <>
      {loader ? <RenderLoader trans={true} /> : null}
      <div className="nk-body bg-white npc-general pg-auth">
        <div className="nk-app-root">
          <div className="nk-main ">
            <div className="nk-wrap nk-wrap-nosidebar">
              <div className="nk-content ">
                <div className="nk-block nk-block-middle nk-auth-body  wide-xs">
                  <div className="brand-logo pb-4 text-center">
                    <Link to={"/"} className="logo-link">
                      <img
                        className="logo-light logo-img logo-img-lg"
                        src={"/assets/images/logo.svg"}
                        srcSet={"/assets/images/logo.svg"}
                        alt="chumbuddy-logo"
                      />
                      <img
                        className="logo-dark logo-img logo-img-lg"
                        src={"/assets/images/logo.svg"}
                        srcSet={"/assets/images/logo.svg"}
                        alt="chumpbuddy-logo-dark"
                      />
                    </Link>
                  </div>

                  <div className="card card-bordered">
                    <div className="card-inner card-inner-lg">
                      <div className="nk-block-head">
                        <div className="nk-block-head-content">
                          <h4 className="nk-block-title text-center">
                            New Password
                          </h4>
                        </div>
                      </div>
                      <form>

                        <div className="mb-3">
                          <input
                            type="text"
                            className="form-control"
                            name="password"
                            placeholder="Enter your new password..."
                            onChange={(e) => {
                              setPassword(e.target.value);
                            }}
                            disabled={enableInputBox}
                          />
                        </div>

                        <div className="form-group">
                          <button
                            className="btn btn-lg btn-primary btn-block"
                            onClick={handleResetPassword}
                            disabled={enableInputBox}
                          >Update</button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="nk-footer nk-auth-footer-full">
                  <div className="container wide-lg">
                    <div className="row g-3">
                      <div className="col-lg-12">
                        <div className="nk-block-content text-center text-lg-center">
                          <p className="text-soft">&copy; 2023 TutorBits. All Rights Reserved.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Toaster position="top-center" reverseOrder={false} duration={3000} />
    </>
  );
};
export default ResetPassword;
