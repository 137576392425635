import { postMethod, putMethod, deleteMethod, getMethod } from '../../Utils/Service';

const getAllAPI = async (data) => {
        const returnData = await postMethod('service', data)
        return returnData;
}

const postAPI = async (data) => {
        const returnData = await postMethod('service/add', data)
        return returnData;
}


const getSingleAPI = async (id) => {
        const returnData = await getMethod('service/edit/' + id);
        return returnData;
}

const putAPI = async (id, data) => {
        const returnData = await putMethod('service/update/' + id, data);
        return returnData;
}

const deleteAPI = async (id) => {
        // const returnData = await getMethod('service/status/' + id);
        const returnData = await deleteMethod('service/remove/' + id);
        return returnData;
}


export { postAPI, putAPI, deleteAPI, getAllAPI, getSingleAPI };