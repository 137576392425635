import React, { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import RenderLoader from "../PanelComponent/RenderLoader";
import { changePassword } from "../Utils/action";

const ChangePassword = () => {

  const history = useNavigate();
  const [editCase, setEditCase] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [loader, setLoader] = useState(false);

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);
    if (
      oldPassword != "" &&
      newPassword != ""
    ) {
      let data = {
        old_password: oldPassword,
        new_password: newPassword,
      };

      changePassword(data).then((response) => {
        if (response.status) {
          toast.success(response.message);
          sessionStorage.setItem("token", response.token);
          setLoader(false);
          setOldPassword("");
          setNewPassword("");
          // history("/admin");

        } else {
          toast.error(response.message);
          setLoader(false);
        }
      });

    } else {
      toast.error("Please fill all required field!");
      setLoader(false);
    }
  };

  useEffect(() => {
  }, []);
  return (
    <>
      <div className="nk-content">
        <div className="container-fluid">
          <div className="nk-content-inner">
            <div className="nk-content-body">
              <div className="nk-block">
                <div className="card card-bordered">
                  <div className="card-aside-wrap">
                    <div className="card-inner card-inner-lg">
                      <div className="nk-block-head nk-block-head-lg">
                        <div className="nk-block-head-content">
                          <h4 className="nk-block-title">
                            Change Password
                          </h4>
                        </div>
                      </div>
                      {loader ? <RenderLoader trans={true} /> : null}
                      <div className="nk-block">
                        <div className="nk-data data-list">
                          {/* <div className="data-head">
                            <h6 className="overline-title">Normal Details</h6>
                          </div> */}


                          <div className="data-item">
                            <div className="data-col">
                              <span className="data-label">Old Password*</span>
                              <div
                                className="form-control-wrap"
                                style={{ width: "100%" }}
                              >
                                <input
                                  type="text"
                                  value={oldPassword}
                                  name="oldPassword"
                                  placeholder="Enter old password"
                                  onChange={(e) => {
                                    setOldPassword(e.target.value);
                                  }}
                                  required
                                  className="form-control form-control-lg"
                                  style={{ width: "100%" }}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="data-item">
                            <div className="data-col">
                              <span className="data-label">New Password*</span>
                              <div
                                className="form-control-wrap"
                                style={{ width: "100%" }}
                              >
                                <input
                                  type="text"
                                  value={newPassword}
                                  name="newPassword"
                                  placeholder="Enter new password"
                                  onChange={(e) => {
                                    setNewPassword(e.target.value);
                                  }}
                                  required
                                  className="form-control form-control-lg"
                                  style={{ width: "100%" }}
                                />
                              </div>
                            </div>
                          </div>


                        </div>

                        <div className="nk-data data-list">
                          <div className="data-item">
                            <div className="data-col">
                              <div
                                className="form-control-wrap"
                                style={{
                                  width: "100%",
                                  paddingLeft: "80%"
                                }}
                              >
                                <button
                                  className="btn btn-lg btn-primary btn-block"
                                  onClick={(e) => handleFormSubmit(e)}
                                >Save</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Toaster position="top-center" reverseOrder={false} duration={3000} />
    </>
  );
};
export default ChangePassword;
