import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { useNavigate, Link } from 'react-router-dom';
import { dateFormatConverter } from '../../Utils/Logic';
import { getAllAPI, deleteAPI, getGradeListAPI, getSubjectListAPI, getAllExcelExportAPI, tutorAccountStatus } from './Action';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import toast, { Toaster } from 'react-hot-toast';
import RenderLoader from '../../PanelComponent/RenderLoader';
import { getCookies } from '../../Utils/cookies';
import Modal from "react-modal";
import user_ic from "../../assets/img/user_ic.svg";
import { ExportToExcel } from '../ExportToExcel';



const Tutor = () => {
    const history = useNavigate();
    const [totalProduct, setTotalProduct] = useState(0);
    const [dataList, setDataList] = useState([]);
    const [loader, setLoader] = useState(true);
    const [gradeList, setGradeList] = useState([]);
    const [grade, setGrade] = useState([]);
    const [subjectList, setSubjectList] = useState([]);
    const [subject, setSubject] = useState([]);
    const [paginationIndex, setPaginationIndex] = useState(1);
    const [search, setSearch] = useState('');
    const [activeStatus, setActiveStatus] = useState('');
    const [adminStatus, setAdminStatus] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [hideClass, setHideClass] = useState({});
    const [profileStatus, setProfileStatus] = useState('');
    const [excelData, setExcelData] = useState([]);

    const customStyles = {
        content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            maxWidth: "500px",
            wordWrap: "break-word"
        },
    };

    const getListAPI = async (limit, page, search, grade, subject, activeStatus, adminStatus) => {
        let postBodyObj = {
            limit: limit,
            page: page,
            search: search,
            role: 1,
            grade: grade,
            subject: subject,
            status: activeStatus,
            admin_status: adminStatus
        };
        await getAllAPI(postBodyObj).then(response => {
            if (response.status) {
                setDataList(response.data);
                setTotalProduct(response.total)
                setLoader(false)
            } else {
                setLoader(false)
            }
        })
        await getAllExcelExportAPI(postBodyObj).then(response => {
            if (response.status) {
                // reshaping the array for excel import
                const customHeadings = response.data.map(item => ({
                    "Name": item?.name,
                    "Email": item?.email,
                    "Mobile": item?.mobile,
                    "Gender": item?.gender,
                    "Age": (item?.age) ? item?.age : '',
                    "Years of experience": item?.totol_experience,
                    "Education": item?.education,
                    "Location": item?.location,
                    "Rating": item?.rating,
                    "commentCount ": item?.commentCount,
                    "Fes": item?.fees,
                    "BIO": item?.intro?.replace(/(<([^>]+)>)/ig, ''),
                    "Status": item?.admin_status,
                    "Created On": dateFormatConverter(item?.createdAt),
                    "Status": (item?.active) ? "Active" : "In-active",
                }))
                setExcelData(customHeadings);
                setLoader(false)
            } else {
                setLoader(false)
            }
        })
    };

    const confirmAction = (id, index) => {
        confirmAlert({
            title: 'Confirm Action',
            message: 'Are you sure you want to delete?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {
                        setLoader(true)
                        await deleteAPI(id).then(response => {
                            if (response?.status === true) {
                                getListAPI(50, paginationIndex, search, grade, subject, activeStatus, adminStatus)
                                toast.success(response.message);
                                setLoader(false)
                            } else {
                                toast.error(response.message)
                                setLoader(false)
                            }
                        })
                    }
                },
                {
                    label: 'No',
                    onClick: () => { }
                }
            ]
        });
    }

    const handleNannyStatus = async (status, id) => {
        const data = {
            id: id,
            status: status
        };
        await tutorAccountStatus(data).then(async (response) => {
            if (response?.status) {
                toast.success(response.message);
                if (getCookies('_isJwt') === 'true' ? true : false) {
                    getListAPI(50, paginationIndex, search, grade, subject, activeStatus, adminStatus);
                }
            }
        })
    }

    const getListing = async () => {
        //----listing----//
        await getGradeListAPI().then(response => {
            if (response.status) {
                setGradeList(response.data);
                setLoader(false)
            } else {
                setLoader(false)
            }
        })

        //----location listing----//
        await getSubjectListAPI().then(response => {
            if (response.status) {
                setSubjectList(response.data);
                setLoader(false)
            } else {
                setLoader(false)
            }
        })
    };

    const handleSubmitSearch = async (e) => {
        e.preventDefault();
        const { search } = e.target.elements;
        setSearch(search.value)
        getListAPI(50, 1, search.value, grade, subject, activeStatus, adminStatus);
    }
    
    const handleSubmitSearchP = async (e) => {
        e.preventDefault();
        const search = e.target.value;
        setSearch(search)
        getListAPI(50, 1, search, grade, subject, activeStatus, adminStatus);
    }


    const handleActiveStatus = async (value) => {
        setActiveStatus(value);
    }

    const handleAdminStatus = async (value) => {
        setAdminStatus(value);
    }



    const handleSubmitGrade = async (value) => {
        setGrade(value);
    }

    const handleSubject = async (value) => {
        setSubject(value);
    }

    const handleAdvanceSearch = async (e) => {
        e.preventDefault();
        getListAPI(50, 1, search, grade, subject, activeStatus, adminStatus);
        close();
    }



    const openModal = () => {
        setHideClass({
            position: "relative",
            zIndex: "-1"
        })
        setIsOpen(true);
    };
    const close = () => {
        setIsOpen(false);
        setHideClass({})
    };

    const reset = () => {
        setIsOpen(false);
        setHideClass({})
        setGrade("");
        setSubject("");
        setActiveStatus("");
        setAdminStatus("");
        setSearch("");
        getListAPI(50, 1);
    };

    useEffect(() => {
        window.scroll(0, 0);
        if (getCookies('_isJwt') === 'true' ? true : false) {
            getListAPI(50, 1, search, grade, subject, activeStatus, adminStatus);
            getListing();
        }
    }, []);

    var divisor = (totalProduct / 50) >> 0;
    var remainder = totalProduct % 50;
    return (
        <>
            <div className="sideBarUL nk-content" >
                <div className="container-fluid" >
                    <div className="nk-content-inner" >
                        <div className="nk-content-body" >
                            <div className="nk-block" >
                                <div className="card card-bordered" >
                                    <div className="card-aside-wrap" >
                                        <div className="card-inner card-inner-lg">
                                            <div className="nk-block-head nk-block-head-lg" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                <div className="nk-block-head-content">
                                                    <h4 className="nk-block-title">Tutors</h4>
                                                    <div className="nk-block-des text-soft">
                                                        {
                                                            totalProduct ?
                                                                <p>You have total {totalProduct === 1 ? totalProduct + ' Tutor' : totalProduct + ' Tutors'}</p>
                                                                : null
                                                        }
                                                    </div>
                                                </div>


                                                <div className="toggle-wrap nk-block-tools-toggle">
                                                    <a
                                                        href="#"
                                                        className="btn btn-icon btn-trigger toggle-expand mr-n1"
                                                        data-target="pageMenu"
                                                    >
                                                        <em className="icon ni ni-menu-alt-r"></em>
                                                    </a>
                                                    <div
                                                        className="toggle-expand-content"
                                                        data-content="pageMenu"
                                                    >
                                                        <ul className="nk-block-tools g-3">
                                                            <li>
                                                                <ExportToExcel apiData={excelData} fileName="Tutors" />
                                                            </li>
                                                            <li>
                                                                <Link to="/admin/tutor-create" className="btn btn-info">Add Tutor</Link>
                                                            </li>

                                                            <li>
                                                                <span onClick={openModal} className="btn btn-success">Search Filter</span>
                                                            </li>
                                                            <li>
                                                                <span onClick={reset} className="btn btn-success">Reset</span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>

                                            </div>

                                            {
                                                loader ?
                                                    <RenderLoader trans={true} />
                                                    : null
                                            }
                                            <div style={{ width: '100%' }}>
                                                <div className="nk-block">
                                                    <div className="card card-bordered card-stretch">
                                                        <div className="card-inner-group">
                                                            <div className="card-inner position-relative card-tools-toggle" style={hideClass}>
                                                                <div className='card-search search-wrap active' data-search="category">
                                                                    <div className="card-body">
                                                                        <form className="search-content" onSubmit={(e) => { handleSubmitSearch(e) }}>
                                                                            <input type="text" name='search' id='search' className="form-control border-transparent form-focus-none" placeholder="Search by title..." style={{ paddingLeft: '0px', textTransform: 'none' }} value={search} onChange={(e) => { handleSubmitSearchP(e) }} />
                                                                            <button className="search-submit btn btn-icon"><em className="icon ni ni-search"></em></button>
                                                                        </form>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="card-inner p-0">
                                                                <div className="nk-tb-list nk-tb-ulist">
                                                                    <div className="nk-tb-item nk-tb-head">

                                                                        <div className="nk-tb-col text-center"><span className="sub-text">Image</span></div>
                                                                        <div className="nk-tb-col text-center"><span className="sub-text">UniqueId</span></div>
                                                                        <div className="nk-tb-col text-center"><span className="sub-text">Name</span></div>
                                                                        <div className="nk-tb-col text-center"><span className="sub-text">Email</span></div>
                                                                        <div className="nk-tb-col text-center"><span className="sub-text">Mobile</span></div>
                                                                        {/* <div className="nk-tb-col text-center"><span className="sub-text">Education</span></div> */}
                                                                        {/* <div className="nk-tb-col text-center"><span className="sub-text">Experience</span></div> */}
                                                                        <div className="nk-tb-col text-center"><span className="sub-text">Rating</span></div>
                                                                        <div className="nk-tb-col text-center"><span className="sub-text">Document Verified</span></div>
                                                                        <div className="nk-tb-col text-center"><span className="sub-text">Verify<br/> Status</span></div>
                                                                        <div className="nk-tb-col tb-col-md"><span className="sub-text text-center">Created On</span></div>
                                                                        <div className="nk-tb-col tb-col-md"><span className="sub-text text-center">Status</span></div>
                                                                        <div className="nk-tb-col tb-col-md"><span className="sub-text text-center">Is Deleted</span></div>
                                                                        <div className="nk-tb-col tb-col-md"><span className="sub-text text-center">More</span></div>
                                                                       
                                                                    </div>
                                                                    {
                                                                        dataList?.length ?
                                                                            dataList?.map((data, index) =>
                                                                                <div className="nk-tb-item" key={index}>


                                                                                    <div className="nk-tb-col">
                                                                                        <div className="image-box">
                                                                                            <Link to={process.env.REACT_APP_URL + '/' + data?.image} download target='_blank'>
                                                                                                {data?.image ?
                                                                                                    <img className="images" src={process.env.REACT_APP_URL + '/' + data?.image} style={{ maxWidth: "50px", maxHeight: "50px" }} />
                                                                                                    :
                                                                                                    <img className="images" src={user_ic} style={{ maxWidth: "50px", maxHeight: "50px" }} />
                                                                                                }
                                                                                            </Link>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div className="nk-tb-col tb-col-lg">
                                                                                        <span className="sub-text text-center">{data?.uniqueId}</span>
                                                                                    </div>

                                                                                    <div className="nk-tb-col tb-col-lg">
                                                                                        <span className="sub-text text-center">{data?.name}</span>
                                                                                    </div>
                                                                                    <div className="nk-tb-col tb-col-lg">
                                                                                        <span className="sub-text text-center">{data?.email}</span>
                                                                                    </div>
                                                                                    <div className="nk-tb-col tb-col-lg">
                                                                                        <span className="sub-text text-center">{data?.mobile}</span>
                                                                                    </div>

                                                                                    {/* <div className="nk-tb-col tb-col-lg">
                                                                                        <span className="sub-text text-center">{data?.education}</span>
                                                                                    </div> */}

                                                                                    {/* <div className="nk-tb-col tb-col-lg">
                                                                                        <span className="sub-text text-center">{data?.totol_experience}</span>
                                                                                    </div> */}

                                                                                    <div className="nk-tb-col tb-col-lg">
                                                                                        <span className="sub-text text-center">{data?.rating}</span>
                                                                                    </div>

                                                                                    <div className="nk-tb-col">
                                                                                        <div className="image-box">
                                                                                            {data?.document ?
                                                                                                <Link to={process.env.REACT_APP_URL + '/' + data?.document} download target='_blank'>
                                                                                                    <span className="sub-text text-center">
                                                                                                        <span className="badge badge-info">File</span>
                                                                                                    </span>
                                                                                                </Link>
                                                                                                : 
                                                                                                <Link to="#">
                                                                                                    <span className="sub-text text-center">
                                                                                                        <span className="badge badge-danger">N/A</span>
                                                                                                    </span>
                                                                                                </Link>
                                                                                            }
                                                                                        </div>
                                                                                    </div>

                                                                                    <div className="nk-tb-col tb-col-lg">
                                                                                        <select name="admin_status" value={data?.admin_status} onChange={(e) => handleNannyStatus(e.target.value, data?._id)} style={{ textTransform: 'uppercase', padding: '5px 5px', }} >
                                                                                            <option value="pending" key="pending">Pending</option>
                                                                                            <option value="approve" key="approve">Approved</option>
                                                                                            <option value="decline" key="decline">Declined</option>
                                                                                        </select>
                                                                                    </div>

                                                                                    <div className="nk-tb-col tb-col-lg">
                                                                                        <span className="sub-text text-center">{dateFormatConverter(data?.createdAt)}</span>
                                                                                    </div>

                                                                                    <div className="nk-tb-col tb-col-lg">
                                                                                        <span className="sub-text text-center">{data?.active ? (<span className="badge badge-success">Active</span>) :
                                                                                            <span className="badge badge-danger">In Active</span>
                                                                                        }</span>
                                                                                    </div>

                                                                                    <div className="nk-tb-col tb-col-lg">
                                                                                        <span className="sub-text text-center">{data?.deleted ? (<span className="badge badge-danger">Deleted</span>) :
                                                                                            <span className="badge badge-success">Active</span>
                                                                                        }</span>
                                                                                    </div>

                                                                                    <div className="nk-tb-col nk-tb-col-tools">
                                                                                        <ul className="nk-tb-actions gx-1">
                                                                                            <li>
                                                                                                <div className="drodown">
                                                                                                    <a href="#" className="dropdown-toggle btn btn-icon btn-trigger" data-toggle="dropdown"><em className="icon ni ni-more-h"></em></a>
                                                                                                    <div className="dropdown-menu dropdown-menu-right">
                                                                                                        <ul className="link-list-opt no-bdr">
                                                                                                            <li><a onClick={(e) => { history('/admin/tutor-edit/' + data?._id) }}><em className="icon ni ni-pen"></em><span>Edit</span></a></li>
                                                                                                            <li><a onClick={(e) => confirmAction(data?._id, index)}><em className="icon ni ni-trash"></em><span>Delete</span></a></li>
                                                                                                        </ul>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </li>
                                                                                        </ul>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                            : null
                                                                    }
                                                                </div>
                                                                {
                                                                    dataList?.length ?
                                                                        null
                                                                        :
                                                                        <div className='row justify-content-center fullDiv' style={{ marginLeft: '0px' }}>
                                                                            <p className='tb-status text-success sub-text text-center' style={{ fontSize: '16px', fontWeight: '600', lineHeight: '36px' }}>
                                                                                Not Found!
                                                                            </p>
                                                                        </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='' style={{ padding: '15px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                {

                                                    totalProduct > 1 ?
                                                        <ReactPaginate
                                                            previousLabel={"Prev"}
                                                            nextLabel={"Next"}
                                                            breakLabel={"..."}
                                                            breakClassName={"break-me"}
                                                            pageCount={remainder >= 1 ? divisor + 1 : divisor}
                                                            marginPagesDisplayed={2}
                                                            pageRangeDisplayed={5}
                                                            onPageChange={async (e) => { setLoader(true); setPaginationIndex(e.selected + 1); getListAPI(50, e.selected + 1, search, grade, subject, activeStatus, adminStatus) }}
                                                            containerClassName={"pagination"}
                                                            subContainerClassName={"pages pagination"}
                                                            activeClassName={"active"} />
                                                        :
                                                        null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
            <Modal
                ariaHideApp={false}
                isOpen={isOpen}
                style={customStyles}
                contentLabel={"Advance Filter"}
            >
                <div className="card-inner card-inner-lg p-2">
                    <div className="nk-block-head nk-block-head-lg">
                        <div className="nk-block-head-content" style={{ display: "flex" }}>
                            <h4
                                className="nk-block-title"
                                style={{ fontSize: "25px", fontWeight: "400" }}
                            >Advance Filter</h4>
                            <a onClick={close} style={{ marginInlineStart: "auto" }}><h4>X</h4></a>
                        </div>
                        <div className="nk-block-head-content" style={{ display: "flex" }}>

                            <div className="nk-block">
                                <div className="nk-data data-list">
                                    <div className="data-head">
                                        <h6 className="overline-title">Normal Details</h6>
                                    </div>

                                    <div className="data-item col-lg-6">
                                        <div className="data-col">
                                            <div className="form-control-wrap" style={{ width: "100%" }}>
                                                <span className="data-label">Grade</span>
                                                <select className="form-control form-control-lg" value={grade} onChange={(e) => handleSubmitGrade(e.target.value)} style={{ textTransform: 'uppercase', width: '100%', padding: '5px 5px', }} >
                                                    <option value="">Select Grade</option>
                                                    {
                                                        gradeList?.length ?
                                                            gradeList?.map((n, index) => <option value={n?._id} key={index}>{n?.name ? n?.name : ''}</option>)
                                                            : null
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="data-item col-lg-6">
                                        <div className="data-col">
                                            <div className="form-control-wrap" style={{ width: "100%" }}>
                                                <span className="data-label">Subject</span>
                                                <select className="form-control form-control-lg" value={subject} onChange={(e) => handleSubject(e.target.value)} style={{ textTransform: 'uppercase', width: '100%', padding: '5px 5px', }} >
                                                    <option value="">Select Subject</option>
                                                    {
                                                        subjectList?.length ?
                                                            subjectList?.map((n, index) => <option value={n?._id} key={index}>{n?.name ? n?.name : ''}</option>)
                                                            : null
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="data-item col-lg-6">
                                        <div className="data-col">
                                            <div className="form-control-wrap" style={{ width: "100%" }}>
                                                <span className="data-label">Active Status</span>
                                                <select value={activeStatus} className="form-control form-control-lg" onChange={(e) => handleActiveStatus(e.target.value)} style={{ textTransform: 'uppercase', width: '100%', padding: '5px 5px', }} >
                                                    <option value="">Select option</option>
                                                    <option value={false}>In Active</option>
                                                    <option value={true}>Active</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="data-item col-lg-6">
                                        <div className="data-col">
                                            <div className="form-control-wrap" style={{ width: "100%" }}>
                                                <span className="data-label">Verify Status</span>
                                                <select value={adminStatus} className="form-control form-control-lg" onChange={(e) => handleAdminStatus(e.target.value)} style={{ textTransform: 'uppercase', width: '100%', padding: '5px 5px', }} >
                                                    <option value="">Select option</option>
                                                    <option value="pending">Pending</option>
                                                    <option value="approve">Approved</option>
                                                    <option value="decline">Declined</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                    <div className='text-center'>
                        <button className="btn btn-lg btn-primary" onClick={reset}>Reset</button>&nbsp;&nbsp;
                        <button onClick={handleAdvanceSearch} className="btn btn-lg btn-primary">Search</button>
                    </div>
                </div>
            </Modal>
            <Toaster
                position="top-center"
                reverseOrder={false}
                duration={3000}
            />
        </>
    )
}
export default Tutor;
