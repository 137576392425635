import React, { lazy, Suspense, useEffect } from "react";
import RenderLoader from "../../../PanelComponent/RenderLoader";
import { setCookies, getCookies } from "../../../Utils/cookies";
import { getMethod, setAuthorizationToken } from "../../../Utils/Service";
import { removeSession } from "../../../Utils/storage";
const LoginSection = lazy(() => import("../Components/LoginSection"));

const renderLoader = () => <RenderLoader trans={false} />;
const Login = () => {

  const fetchData = async () => {
    try {
      setAuthorizationToken(true);
      await getMethod("auth/getUser").then((response) => {
        if (response.status) {
          setCookies(
            "admin_details",
            JSON.stringify({
              name: response.data.name,
              email: response.data.email,
              id: response.data._id,
              mobile: response.data.contactNumber,
              role: response.data.role,
            })
          );
          setCookies("_isJwt", true);
          setAuthorizationToken(true);
          sessionStorage.setItem("role", response.data.role);
          window.open("/admin/dashboard", "_self");
        } else {
          setCookies("_isJwt", false);
          setAuthorizationToken(false);
          setCookies("admin_details", "");
          sessionStorage.removeItem("role");
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    // fetchData();
  }, []);
  return (
    <Suspense fallback={renderLoader()}>
      <LoginSection />
    </Suspense>
  );
};
export default Login;
