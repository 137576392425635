import { postMethod, putMethod, deleteMethod, getMethod } from '../../Utils/Service';

const getAllAPI = async (data) => {
        const returnData = await postMethod('city', data)
        return returnData;
}

const postAPI = async (data) => {
        const returnData = await postMethod('city/add', data)
        return returnData;
}

const countryListApi = async () => {
        const returnData = await getMethod('common/country')
        return returnData;
}

const getSingleAPI = async (id) => {
        const returnData = await getMethod('city/edit/' + id);
        return returnData;
}

const putAPI = async (id, data) => {
        const returnData = await putMethod('city/update/' + id, data);
        return returnData;
}

const deleteAPI = async (id) => {
        // const returnData = await getMethod('city/status/' + id);
        const returnData = await deleteMethod('city/remove/' + id);
        return returnData;
}


export { postAPI, putAPI, deleteAPI, getAllAPI, getSingleAPI,countryListApi };