import { postMethod } from '../../Utils/Service';

const getAllAPI = async (data) => {
        const returnData = await postMethod('payment', data)
        return returnData;
}

const getAllExcelExportAPI = async (data) => {
        const returnData = await postMethod('payment/excelExport', data)
        return returnData;
}

export { getAllAPI,getAllExcelExportAPI };