import { postMethod } from '../../Utils/Service';

const getAllAPI = async (data) => {
        const returnData = await postMethod('finance', data)
        return returnData;
}

const getAllExcelExportAPI = async (data) => {
        const returnData = await postMethod('finance/excelExport', data)
        return returnData;
}

export { getAllAPI,getAllExcelExportAPI };