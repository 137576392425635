import { postMethod, putMethod, deleteMethod, getMethod } from '../../Utils/Service';

const getAllAPI = async (data) => {
        const returnData = await postMethod('language', data)
        return returnData;
}

const postAPI = async (data) => {
        const returnData = await postMethod('language/add', data)
        return returnData;
}


const getSingleAPI = async (id) => {
        const returnData = await getMethod('language/edit/' + id);
        return returnData;
}

const putAPI = async (id, data) => {
        const returnData = await putMethod('language/update/' + id, data);
        return returnData;
}

const deleteAPI = async (id) => {
        // const returnData = await getMethod('language/status/' + id);
        const returnData = await deleteMethod('language/remove/' + id);
        return returnData;
}

export { postAPI, putAPI, deleteAPI, getAllAPI, getSingleAPI };