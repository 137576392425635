import { postMethod, putMethod, deleteMethod, getMethod } from './Service';


const changePassword = async (data) => {
    const returnData = await postMethod('auth/changePassword', data)
    return returnData;
}

const updatePercentage = async (data) => {
    const returnData = await postMethod('admin/percentageUpdate', data)
    return returnData;
}

const getPercentage = async () => {
    const returnData = await getMethod('admin/getuser')
    return returnData;
}

export { changePassword, updatePercentage, getPercentage };