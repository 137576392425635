import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useLocation, Link } from "react-router-dom";
import { dateFormatConverter } from "../../Utils/Logic";
import { getAllAPI, deleteAPI } from "./Action";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import toast, { Toaster } from "react-hot-toast";
import RenderLoader from "../../PanelComponent/RenderLoader";
import { getCookies } from "../../Utils/cookies";
import Modal from "react-modal";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}
const ContactUs = () => {
    const [totalProduct, setTotalProduct] = useState(0);
    const [dataList, setDataList] = useState([]);
    const [loader, setLoader] = useState(true);
    const [paginationIndex, setPaginationIndex] = useState(1);
    const [search, setSearch] = useState("");
    const [isOpen, setIsOpen] = useState(false);
    const [singleData, setSingleData] = useState({});
    const [hideClass, setHideClass] = useState({});

    const customStyles = {
        content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            maxWidth: "500px",
            wordWrap: "break-word"

        },
    };

    const openModal = (data) => {
        setHideClass({
            position: "relative",
            zIndex: "-1"
        })
        setIsOpen(true);
        setSingleData(data);
    };
    const close = () => {
        setIsOpen(false);
        setHideClass({})
    };

    const getListAPI = async (limit, page, search) => {
        let postBodyObj = {
            limit: limit,
            page: page,
            search: search,
        };
        await getAllAPI(postBodyObj).then((response) => {
            if (response.status) {
                setDataList(response.data);
                setTotalProduct(response.total);
                setLoader(false);
            } else {
                setLoader(false);
            }
        });
    };
    const confirmAction = (id, index) => {
        confirmAlert({
            title: "Confirm Action",
            message: "Are you sure you want to delete?",
            buttons: [
                {
                    label: "Yes",
                    onClick: async () => {
                        setLoader(true);
                        await deleteAPI(id).then((response) => {
                            if (response?.status) {
                                getListAPI(50, paginationIndex, search);
                                toast.success(response.message);
                                setLoader(false);
                            } else {
                                toast.error(response.message);
                                setLoader(false);
                            }
                        });
                    },
                },
                {
                    label: "No",
                    onClick: () => { },
                },
            ],
        });
    };
    const handleSubmitSearch = async (e) => {
        e.preventDefault();
        const { search } = e.target.elements;
        setSearch(search.value);
        getListAPI(50, 1, search.value);
    };
    useEffect(() => {
        if (getCookies("_isJwt") === "true" ? true : false) {
            getListAPI(50, 1, search);
        }

    }, []);

    var divisor = (totalProduct / 50) >> 0;
    var remainder = totalProduct % 50;
    return (
        <>
            <div className="sideBarUL nk-content">
                <div className="container-fluid">
                    <div className="nk-content-inner">
                        <div className="nk-content-body">
                            <div className="nk-block">
                                <div className="card card-bordered">
                                    <div className="card-aside-wrap">
                                        <div className="card-inner card-inner-lg">
                                            <div
                                                className="nk-block-head nk-block-head-lg"
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "space-between",
                                                }}
                                            >
                                                <div className="nk-block-head-content">
                                                    <h4 className="nk-block-title">Contact Us List</h4>
                                                    <div className="nk-block-des text-soft">
                                                        {totalProduct ? (
                                                            <p>
                                                                You have total{" "}
                                                                {totalProduct === 1
                                                                    ? totalProduct + " product"
                                                                    : totalProduct + " products"}
                                                            </p>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className="toggle-wrap nk-block-tools-toggle">
                                                    <a
                                                        href="#"
                                                        className="btn btn-icon btn-trigger toggle-expand mr-n1"
                                                        data-target="pageMenu"
                                                    >
                                                        <em className="icon ni ni-menu-alt-r"></em>
                                                    </a>
                                                    {/* <div
                                                        className="toggle-expand-content"
                                                        data-content="pageMenu"
                                                    >   
                                                    </div> */}
                                                </div>
                                            </div>

                                            {loader ? <RenderLoader trans={true} /> : null}
                                            <div style={{ width: "100%" }}>
                                                <div className="nk-block">
                                                    <div className="card card-bordered card-stretch">
                                                        <div className="card-inner-group">
                                                            <div className="card-inner position-relative card-tools-toggle" style={hideClass}>
                                                                <div
                                                                    className="card-search search-wrap active"
                                                                    data-search="search"

                                                                >
                                                                    <div className="card-body" >
                                                                        <form
                                                                            className="search-content"
                                                                            onSubmit={(e) => {
                                                                                handleSubmitSearch(e);
                                                                            }}
                                                                        >
                                                                            <input
                                                                                type="text"
                                                                                name="search"
                                                                                id="search"
                                                                                className="form-control border-transparent form-focus-none"
                                                                                placeholder="Search by user name/email..."
                                                                                style={{
                                                                                    paddingLeft: "0px",
                                                                                    textTransform: "none",
                                                                                }}
                                                                            />
                                                                            <button className="search-submit btn btn-icon">
                                                                                <em className="icon ni ni-search"></em>
                                                                            </button>
                                                                        </form>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="card-inner p-0">
                                                                <div className="nk-tb-list nk-tb-ulist">
                                                                    <div className="nk-tb-item nk-tb-head">
                                                                        {/* <div className="nk-tb-col nk-tb-col-check">
                                                                            <span className="sub-text text-center">Title</span>
                                                                        </div> */}
                                                                        <div className="nk-tb-col tb-col-lg">
                                                                            <span className="sub-text text-center">
                                                                                Full Name
                                                                            </span>
                                                                        </div>
                                                                        <div className="nk-tb-col tb-col-lg">
                                                                            <span className="sub-text text-center">
                                                                                Email
                                                                            </span>
                                                                        </div>
                                                                        <div className="nk-tb-col tb-col-lg">
                                                                            <span className="sub-text text-center">
                                                                                Mobile
                                                                            </span>
                                                                        </div>
                                                                        <div className="nk-tb-col tb-col-lg">
                                                                            <span className="sub-text text-center">
                                                                                message
                                                                            </span>
                                                                        </div>
                                                                        <div className="nk-tb-col tb-col-lg">
                                                                            <span className="sub-text text-center">
                                                                                Looking For
                                                                            </span>
                                                                        </div>
                                                                        <div className="nk-tb-col tb-col-md">
                                                                            <span className="sub-text text-center">
                                                                                Created At
                                                                            </span>
                                                                        </div>
                                                                        <div className="nk-tb-col nk-tb-col-tools text-right">
                                                                            <span className="sub-text">More</span>
                                                                        </div>
                                                                    </div>
                                                                    {dataList?.length
                                                                        ? dataList?.map((data, index) => (
                                                                            <div className="nk-tb-item" key={index}>
                                                                                <div className="nk-tb-col">
                                                                                    <span className="sub-text text-center">
                                                                                        {data?.first_name +
                                                                                            " " +
                                                                                            data?.last_name}
                                                                                    </span>
                                                                                </div>
                                                                                <div className="nk-tb-col">
                                                                                    <span className="sub-text text-center">
                                                                                        {data?.email}
                                                                                    </span>
                                                                                </div>
                                                                                <div className="nk-tb-col">
                                                                                    <span className="sub-text text-center">
                                                                                        {data?.mobile}
                                                                                    </span>
                                                                                </div>
                                                                                <div className="nk-tb-col">
                                                                                    <span className="sub-text text-center form-control-wrap">
                                                                                        <div dangerouslySetInnerHTML={{
                                                                                            __html: data?.message.substring(
                                                                                                0,
                                                                                                20
                                                                                            ),
                                                                                        }}>
                                                                                        </div>
                                                                                    </span>
                                                                                </div>
                                                                                <div className="nk-tb-col">
                                                                                    <span className="sub-text text-center">
                                                                                        {data?.job_type
                                                                                            ? data?.job_type.name
                                                                                            : ""}
                                                                                    </span>
                                                                                </div>

                                                                                <div className="nk-tb-col">
                                                                                    <span className="sub-text text-center">
                                                                                        {dateFormatConverter(
                                                                                            data?.createdAt
                                                                                        )}
                                                                                    </span>
                                                                                </div>

                                                                                <div className="nk-tb-col nk-tb-col-tools">
                                                                                    <ul className="nk-tb-actions gx-1">
                                                                                        <li>
                                                                                            <div className="drodown">
                                                                                                <a
                                                                                                    href="#"
                                                                                                    className="dropdown-toggle btn btn-icon btn-trigger"
                                                                                                    data-toggle="dropdown"
                                                                                                >
                                                                                                    <em className="icon ni ni-more-h"></em>
                                                                                                </a>
                                                                                                <div className="dropdown-menu dropdown-menu-right">
                                                                                                    <ul className="link-list-opt no-bdr">
                                                                                                        <li>
                                                                                                            <a
                                                                                                                onClick={(e) =>
                                                                                                                    openModal(data)
                                                                                                                }
                                                                                                            >
                                                                                                                <em className="icon ni ni-eye"></em>
                                                                                                                <span>View Message</span>
                                                                                                            </a>
                                                                                                        </li>
                                                                                                        <li>
                                                                                                            <a
                                                                                                                onClick={(e) =>
                                                                                                                    confirmAction(
                                                                                                                        data?._id,
                                                                                                                        index
                                                                                                                    )
                                                                                                                }
                                                                                                            >
                                                                                                                <em className="icon ni ni-trash"></em>
                                                                                                                <span>Delete</span>
                                                                                                            </a>
                                                                                                        </li>
                                                                                                    </ul>
                                                                                                </div>
                                                                                            </div>
                                                                                        </li>
                                                                                    </ul>
                                                                                </div>
                                                                            </div>
                                                                        ))
                                                                        : null}
                                                                </div>
                                                                {dataList?.length ? null : (
                                                                    <div
                                                                        className="row justify-content-center fullDiv"
                                                                        style={{ marginLeft: "0px" }}
                                                                    >
                                                                        <p
                                                                            className="tb-status text-success sub-text text-center"
                                                                            style={{
                                                                                fontSize: "16px",
                                                                                fontWeight: "600",
                                                                                lineHeight: "36px",
                                                                            }}
                                                                        >
                                                                            Not Found!
                                                                        </p>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={hideClass}>
                                                <div
                                                    className=""
                                                    style={{
                                                        padding: "15px",
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    {totalProduct > 1 ? (
                                                        <ReactPaginate
                                                            previousLabel={"Prev"}
                                                            nextLabel={"Next"}
                                                            breakLabel={"..."}
                                                            breakClassName={"break-me"}
                                                            pageCount={remainder >= 1 ? divisor + 1 : divisor}
                                                            marginPagesDisplayed={2}
                                                            pageRangeDisplayed={5}
                                                            onPageChange={async (e) => {
                                                                setLoader(true);
                                                                setPaginationIndex(e.selected + 1);
                                                                getListAPI(50, e.selected + 1, search);
                                                            }}
                                                            containerClassName={"pagination"}
                                                            subContainerClassName={"pages pagination"}
                                                            activeClassName={"active"}
                                                        />
                                                    ) : null}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal
                ariaHideApp={false}
                isOpen={isOpen}
                style={customStyles}
                contentLabel={"User's Message"}
            >
                <div className="card-inner card-inner-lg p-2">
                    <div className="nk-block-head nk-block-head-lg">
                        <div className="nk-block-head-content" style={{ display: "flex" }}>
                            <h4
                                className="nk-block-title"
                                style={{ fontSize: "25px", fontWeight: "400" }}
                            >User's Message</h4>
                            <a onClick={close} style={{ marginInlineStart: "auto" }}>
                                <h4>X</h4>
                            </a>
                        </div>
                        <div className="nk-block-head-content" style={{ display: "flex" }}>
                            <p
                                className=" form-control-lg"
                                style={{ width: "100%", height: "auto", resize: "none" }}
                            >{singleData?.message}</p>
                        </div>

                    </div>
                    <button
                        className="btn btn-lg btn-primary btn-block"
                        onClick={close}
                    >Close</button>
                </div>
            </Modal>

            <Toaster position="top-center" reverseOrder={false} duration={3000} />
        </>
    );
};
export default ContactUs;
