import { postMethod, putMethod, deleteMethod, getMethod } from '../../Utils/Service';

const putAPI = async (id, data) => {
        const returnData = await putMethod('admin/update/' + id, data);
        return returnData;
}
const deleteAPI = async (id) => {
        // const returnData = await deleteMethod('auth/status/' + id);
        const returnData = await deleteMethod('admin/remove/' + id);
        return returnData;
}

const updateStatusAPI = async (id) => {
        const returnData = await getMethod('admin/status/' + id);
        return returnData;
}

const postAPI = async (data) => {
        const returnData = await postMethod('/admin/add', data);
        return returnData;
}

const getAllAPI = async (data) => {
        const returnData = await postMethod('/admin/tutor', data);
        return returnData;
}

const getAllExcelExportAPI = async (data) => {
        const returnData = await postMethod('/admin/excelExport', data);
        return returnData;
}

const getGradeListAPI = async (data) => {
        const returnData = await getMethod('/common/grade');
        return returnData;
}

const getSubjectListAPI = async () => {
        const returnData = await getMethod('/common/subject');
        return returnData;
}

const getSingleAPI = async (id) => {
        const returnData = await getMethod('admin/edit/' + id);
        return returnData;
}

const getJobTypeList = async (id) => {
        const returnData = await getMethod('common/jobtype');
        return returnData;
}

const getLanguageListAPI = async (id) => {
        const returnData = await getMethod('common/language');
        return returnData;
}

const getCountryListAPI = async () => {
        const returnData = await getMethod('/common/country');
        return returnData;
}

const tutorAccountStatus = async (data) => {
        const returnData = await postMethod('/admin/verifyTutorProfile', data);
        return returnData;
}

export { postAPI, putAPI, deleteAPI, getAllAPI, getAllExcelExportAPI, getSingleAPI, getGradeListAPI, updateStatusAPI, getSubjectListAPI, getJobTypeList, getLanguageListAPI,getCountryListAPI,tutorAccountStatus };