import { postMethod, putMethod, deleteMethod, getMethod } from '../../Utils/Service';

const getAllAPI = async (data) => {
        const returnData = await postMethod('grade', data)
        return returnData;
}

const postAPI = async (data) => {
        const returnData = await postMethod('grade/add', data)
        return returnData;
}


const getSingleAPI = async (id) => {
        const returnData = await getMethod('grade/edit/' + id);
        return returnData;
}

const putAPI = async (id, data) => {
        const returnData = await putMethod('grade/update/' + id, data);
        return returnData;
}

const deleteAPI = async (id) => {
        // const returnData = await getMethod('grade/status/' + id);
        const returnData = await deleteMethod('grade/remove/' + id);
        return returnData;
}


export { postAPI, putAPI, deleteAPI, getAllAPI, getSingleAPI };