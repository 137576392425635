import { postMethod, putMethod, deleteMethod, getMethod } from '../../Utils/Service';

const getAllAPI = async (data) => {
        const returnData = await postMethod('country', data)
        return returnData;
}

const postAPI = async (data) => {
        const returnData = await postMethod('country/add', data)
        return returnData;
}

const countryList = async (data) => {
        const returnData = await postMethod('country', data)
        return returnData;
}

const getSingleAPI = async (id) => {
        const returnData = await getMethod('country/edit/' + id);
        return returnData;
}

const putAPI = async (id, data) => {
        const returnData = await putMethod('country/update/' + id, data);
        return returnData;
}

const deleteAPI = async (id) => {
        // const returnData = await getMethod('country/status/' + id);
        const returnData = await deleteMethod('country/remove/' + id);
        return returnData;
}


export { postAPI, putAPI, deleteAPI, getAllAPI, getSingleAPI,countryList };