import { postMethod, putMethod, deleteMethod, getMethod } from '../../Utils/Service';

const getAllAPI = async (data) => {
        const returnData = await postMethod('plan', data)
        return returnData;
}

const postAPI = async (data) => {
        const returnData = await postMethod('plan/add', data)
        return returnData;
}


const getSingleAPI = async (id) => {
        const returnData = await getMethod('plan/edit/' + id);
        return returnData;
}

const putAPI = async (id, data) => {
        const returnData = await putMethod('plan/update/' + id, data);
        return returnData;
}

const deleteAPI = async (id) => {
        const returnData = await deleteMethod('plan/remove/' + id);
        return returnData;
}


export { postAPI, putAPI, deleteAPI, getAllAPI, getSingleAPI };