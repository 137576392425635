import React, { useEffect, useState, useRef } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useLocation, useNavigate, useParams, Link } from "react-router-dom";
import { getSingleAPI, postAPI, putAPI, countryListApi } from "./Action";
import { Editor } from "@tinymce/tinymce-react";
import RenderLoader from "../../PanelComponent/RenderLoader";
import { getCookies } from "../../Utils/cookies";

const CreateCity = () => {
  const { id } = useParams();
  const history = useNavigate();
  const [editCase, setEditCase] = useState(false);
  const [name, setName] = useState("");
  const [active, setActive] = useState(true);
  const [loader, setLoader] = useState(false);
  const [cityList, setCityList] = useState([]);
  const [country, setCountry] = useState("");


  const getCityList = async () => {
    setLoader(true)
    await countryListApi().then(response => {
      if (response.status) {
        setCityList(response.data);
        setLoader(false)
      } else {
        setLoader(false)
      }
    })
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);
    if (
      name != ""
    ) {
      let data = {
        name: name,
        country: country,
        active: active,
      };

      if (editCase) {
        putAPI(id, data).then((response) => {
          if (response.status) {
            // toast.success(response.message);
            setEditCase(false);
            setLoader(false);
            history("/admin/city");
          } else {
            toast.error(response.message);
            setLoader(false);
          }
        });
      } else {
        postAPI(data).then((response) => {
          if (response.status) {
            // toast.success(response.message);
            setEditCase(false);
            setActive(true);
            setLoader(false);
            history("/admin/city");

          } else {
            toast.error(response.message);
            setLoader(false);
          }
        });
      }
    } else {
      toast.error("Please fill all required field!");
      setLoader(false);
    }
  };

  const fetchData = async () => {
    if (getCookies('_isJwt') === 'true' ? true : false) {

      if (window.location.pathname === '/admin/city-edit/' + id && id != '') {
        setLoader(true)
        await getSingleAPI(id).then(response => {
          if (response.status) {
            const data = response?.data;
            if (data) {
              setEditCase(true);
              setName(data?.name ? data?.name : '');
              setCountry(data?.country ? data?.country : '');
              setActive(data?.active ? true : false);
              setLoader(false)
            } else {
              toast.error("some error occured", response.message);
              // history('/404');
            }
          } else {
            toast.error("some error occured", response.message);
            // history('/404');
          }
        });
      }
    }
  }

  useEffect(() => {
    fetchData();
    getCityList();
  }, []);
  return (
    <>
      <div className="nk-content">
        <div className="container-fluid">
          <div className="nk-content-inner">
            <div className="nk-content-body">
              <div className="nk-block">
                <div className="card card-bordered">
                  <div className="card-aside-wrap">
                    <div className="card-inner card-inner-lg">
                      <div className="nk-block-head nk-block-head-lg">
                        <div className="nk-block-head-content">
                          <h4 className="nk-block-title">
                            {editCase ? "Edit Cities" : "Create Cities"}
                          </h4>
                        </div>
                      </div>
                      {loader ? <RenderLoader trans={true} /> : null}
                      <div className="nk-block">
                        <div className="nk-data data-list">
                          <div className="data-head">
                            <h6 className="overline-title">Normal Details</h6>
                          </div>
                          <div className="data-item">
                            <div className="data-col">
                              <span className="data-label">Name*</span>
                              <div
                                className="form-control-wrap"
                                style={{ width: "100%" }}
                              >
                                <input
                                  type="text"
                                  value={name}
                                  name="name"
                                  placeholder="Enter name"
                                  onChange={(e) => {
                                    setName(e.target.value);
                                  }}
                                  autoComplete="off"
                                  required
                                  className="form-control form-control-lg"
                                  style={{ width: "100%" }}
                                  maxLength={100}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="data-item">
                            <div className="data-col">
                              <span className="data-label">Country</span>
                              <div
                                className="form-control-wrap"
                                style={{ width: "100%" }}
                              >
                                <select
                                  type="text"
                                  value={country}
                                  onChange={(e) => setCountry(e.target.value)}
                                  name="country"
                                  className="form-control form-control-lg"
                                  style={{ width: "100%" }}
                                >
                                  <option value="">Select country</option>
                                  {
                                    cityList?.length > 0 ?
                                      cityList?.map((item, index) => <option value={item?._id} key={index}>{item?.name}</option>)
                                      : null
                                  }
                                </select>
                              </div>
                            </div>
                          </div>


                          <div className="data-item">
                            <div className="data-col">
                              <span className="data-label">Is Active*</span>
                              <div
                                className="form-control-wrap"
                                style={{ width: "100%" }}
                              >
                                <select
                                  type="text"
                                  value={active}
                                  onChange={(e) => setActive(e.target.value)}
                                  name="active"
                                  required
                                  className="form-control form-control-lg"
                                  style={{ width: "100%" }}
                                >
                                  <option value={true} key={true}>Active</option>
                                  <option value={false} key={false}>In Active</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="nk-data data-list">
                          <div className="data-item">
                            <div className="data-col">
                              <div
                                className="form-control-wrap"
                                style={{
                                  width: "100%",
                                  paddingLeft: "80%"
                                }}
                              >
                                <button
                                  className="btn btn-lg btn-primary btn-block"
                                  onClick={(e) => handleSubmit(e)}
                                >
                                  {editCase ? "Save" : "Create"}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Toaster position="top-center" reverseOrder={false} duration={3000} />
    </>
  );
};
export default CreateCity;
