import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="nk-footer">
      <div className="container-fluid">
        <div className="nk-footer-wrap">
          <div className="nk-footer-copyright">
            &copy;{new Date().getFullYear()} Tutor Bits
          </div>
          {/* <div className="nk-footer-links">
            <ul className="nav nav-sm">
              <li className="nav-item"></li>
            </ul>
          </div> */}
        </div>
      </div>
    </div>
  );
};
export default Footer;
