import React, { useEffect, useState } from "react";
import { getMethod, setAuthorizationToken } from "../Utils/Service";
import { setCookies, getCookies } from "../Utils/cookies";
import RenderLoader from "../PanelComponent/RenderLoader";
import { Link } from "react-router-dom";


const Dashboard = () => {
  const [loader, setLoader] = useState(true);
  const [year, setYear] = useState('');
  const [dashboardData, setDashboardData] = useState({});

  const fetchDashboardCounter = async (year) => {
    try {
      setAuthorizationToken(true);
      await getMethod("auth/dashboard?year=" + year).then(async (response) => {
        if (response.status) {
          setCookies("_isJwt", true);
          setAuthorizationToken(true);
          setDashboardData(response.data);
        } else {
          setAuthorizationToken(false);
          setCookies("_isJwt", false);
          setCookies("admin_details", "");
        }
        setLoader(false);
      });
    } catch (error) {
      // Handle error
      console.error(error);
    }
  };


  const fetchData = async () => {
    try {
      setAuthorizationToken(true);
      // console.log("********dashboard**********");
      await getMethod("auth/getUser").then(async (response) => {
        if (response.status) {
          // console.log("********dashboard**response********",response);
          setCookies("_isJwt", true);
          setAuthorizationToken(true);
          sessionStorage.setItem("role", response.data.role);
          setCookies(
            "admin_details",
            JSON.stringify({
              name: response.data.name,
              email: response.data.email,
              id: response.data._id,
              mobile: response.data.contactNumber,
              role: response.data.role,
              role: "admin",
            })
          );
        } else {
          setAuthorizationToken(false);
          setCookies("_isJwt", false);
          setCookies("admin_details", "");
          sessionStorage.removeItem("role");
        }
        setLoader(false);
      });
    } catch (error) {
      // Handle error
      console.error(error);
    }
  };

 
  useEffect(() => {
    fetchData(); // Call the async function
    const date = new Date();
    setYear(date.getFullYear());
    fetchDashboardCounter(date.getFullYear()); //--fetching the counter list.
  }, []);
  return (
    <>
      {loader ? <RenderLoader trans={true} /> : null}
      <div className="sideBarUL nk-content">
        <div className="container-fluid">
          <div className="nk-content-inner">
            <div className="nk-content-body">
              <div className="nk-block">
                <div className="row g-gs">
                  <div className="col-xl-12">
                    <div className="row g-gs">
                      <div className="col-lg-12 col-xl-12">
                        <div
                          className="card card-bordered"
                          style={{ background: "transparent", border: "none" }}
                        >
                          <div className="card-inner">
                            <div className="">
                              <div className="nk-block">
                                <div className="row g-gs">
                                  <div className="col-xl-12 fullWidth">
                                    <div
                                      className="row g-gs"
                                      style={{ width: "100%", margin: "0px" }}
                                    >

                                      <>
                                        <div className="col-xl-3 col-lg-3 col-md-6 col-12">
                                          <Link to="/admin/tutor">
                                            <div className="card card-bordered">
                                              <div className="card-inner">
                                                <div className="card-title-group align-start mb-2">
                                                  <div className="card-title">
                                                    <h6 className="title">
                                                      Total Tutors
                                                    </h6>
                                                  </div>
                                                </div>
                                                <div className="align-end flex-sm-wrap g-4 flex-md-nowrap">
                                                  <div className="nk-sale-data">
                                                    <span className="amount">
                                                      {dashboardData?.tutor}
                                                    </span>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </Link>
                                        </div>

                                        <div className="col-xl-3 col-lg-3 col-md-6 col-12">
                                          <Link to="/admin/student">
                                            <div className="card card-bordered">
                                              <div className="card-inner">
                                                <div className="card-title-group align-start mb-2">
                                                  <div className="card-title">
                                                    <h6 className="title">
                                                      Total Students                                                      </h6>
                                                  </div>
                                                </div>
                                                <div className="align-end flex-sm-wrap g-4 flex-md-nowrap">
                                                  <div className="nk-sale-data">
                                                    <span className="amount">
                                                      {dashboardData?.student}
                                                    </span>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </Link>
                                        </div>

                                        <div className="col-xl-3 col-lg-3 col-md-6 col-12">
                                          <Link to="/admin/finance">
                                            <div className="card card-bordered">
                                              <div className="card-inner">
                                                <div className="card-title-group align-start mb-2">
                                                  <div className="card-title">
                                                    <h6 className="title">Active Subscribers</h6>
                                                  </div>
                                                </div>
                                                <div className="align-end flex-sm-wrap g-4 flex-md-nowrap">
                                                  <div className="nk-sale-data">
                                                    <span className="amount">
                                                      {dashboardData?.subscription}
                                                    </span>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </Link>
                                        </div>

                                        <div className="col-xl-3 col-lg-3 col-md-6 col-12">
                                          <Link to="/admin/payment">
                                            <div className="card card-bordered">
                                              <div className="card-inner">
                                                <div className="card-title-group align-start mb-2">
                                                  <div className="card-title">
                                                    <h6 className="title">Total Transaction</h6>
                                                  </div>
                                                </div>
                                                <div className="align-end flex-sm-wrap g-4 flex-md-nowrap">
                                                  <div className="nk-sale-data">
                                                    <span className="amount">
                                                      {dashboardData?.transaction}
                                                    </span>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </Link>
                                        </div>
                                      </>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Dashboard;
